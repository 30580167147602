/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OfferDataObject } from '../models/OfferDataObject';
import type { PriceDataObject } from '../models/PriceDataObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OffersService {
    /**
     * @param userId
     * @returns OfferDataObject OK
     * @throws ApiError
     */
    public static getApiOffersCurrent(
        userId?: string,
    ): CancelablePromise<OfferDataObject> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/offers/current',
            headers: {
                'UserId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteApiOffersCurrent(
        userId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/offers/current',
            headers: {
                'UserId': userId,
            },
        });
    }
    /**
     * @param componentId
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static putApiOffersCurrentComponents(
        componentId: string,
        userId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/offers/current/components/{component_id}',
            path: {
                'component_id': componentId,
            },
            headers: {
                'UserId': userId,
            },
        });
    }
    /**
     * @param buildId
     * @param userId
     * @returns OfferDataObject OK
     * @throws ApiError
     */
    public static putApiOffersCurrentComponentsFromBuild(
        buildId: string,
        userId?: string,
    ): CancelablePromise<OfferDataObject> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/offers/current/components/from-build/{build_id}',
            path: {
                'build_id': buildId,
            },
            headers: {
                'UserId': userId,
            },
        });
    }
    /**
     * @param componentId
     * @param userId
     * @param requestBody
     * @returns OfferDataObject OK
     * @throws ApiError
     */
    public static putApiOffersCurrentCounteroffer(
        componentId: string,
        userId?: string,
        requestBody?: PriceDataObject,
    ): CancelablePromise<OfferDataObject> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/offers/current/counteroffer/{component_id}',
            path: {
                'component_id': componentId,
            },
            headers: {
                'UserId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
