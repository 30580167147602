import React, { useState } from 'react';
import { Box, Card, CardMedia, CardContent, CardActions, styled, Container, Grid, Button, Typography, MenuItem, Paper, useTheme } from '@mui/material';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import componentCategories from '../app/types/ComponentCategories';
import { ComponentCategory } from '../app/types/ComponentCategory';
import ComponentManufacturer from '../app/types/ComponentManufacturer';
import { ComponentFilter } from '../app/ComponentsApi';
import SocialProof from './Socialproof';

interface CategoryProps {
    title: string;
    image: string;
}


const CategoryMenu: React.FC<{ category: ComponentCategory }> = ({ category }) => {
    const popupState = usePopupState({
        variant: 'popover',
        popupId: `${category}-menu`,
    });

    const navigate = useNavigate();
    const theme = useTheme(); // Get the current theme

    const handleMenuItemClick = (manufacturer: ComponentManufacturer) => {
        let url = '/components';
        let filter = new ComponentFilter(category.type.name, manufacturer.name);
        url += '?' + filter.encodeAsURIQuery();
        navigate(url);
        popupState.close();
    };

    return (
        <React.Fragment>
            <Button
                variant="text"
                endIcon={<ExpandMore style={{ marginLeft: '-6px' }} />}
                style={{ 
                    color: theme.palette.text.primary, // Use theme color
                    backgroundColor: `rgba(0, 0, 0, 0)`, // Use theme background
                }}
                {...bindHover(popupState)}
            >
                {category.type.name}
            </Button>
            <HoverMenu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                    style: {
                        backgroundColor: theme.palette.background.paper, // Use theme background
                        color: theme.palette.text.primary, // Use theme text color
                    },
                }}
                {...bindMenu(popupState)}
            >
                {category.manufacturers.map((manufacturer, index) => (
                    <MenuItem 
                        key={index} 
                        onClick={() => handleMenuItemClick(manufacturer)}
                        style={{
                            color: theme.palette.text.primary, // Use theme text color
                        }}
                    >
                        {manufacturer.name}
                    </MenuItem>
                ))}
            </HoverMenu>
        </React.Fragment>
    );
};

// Define the styles for the cards and shadow
const StyledPaper = styled(Paper)(({ theme }) => ({
    boxSizing: 'border-box',
    width: 254,
    height: 254,
    background: 'rgba(217, 217, 217, 0.58)',
    border: '1px solid white',
    boxShadow: '12px 17px 51px rgba(0, 0, 0, 0.22)',
    backdropFilter: 'blur(6px)',
    borderRadius: '17px',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.5s',
    display: 'flex',
    flexDirection: 'column',  // Ensure content is vertically stacked
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    fontWeight: 'bolder',
    color: 'black',
    '&:hover': {
      border: '1px solid black',
      transform: 'scale(1.05)',
    },
    '&:active': {
      transform: 'scale(0.95)',
    },
  }));
  
  const CategoryGrid: React.FC<CategoryProps> = ({ title, image }) => {
    return (
      <Grid item xs={12} sm={6} md={3}>
        <StyledPaper>
          <CardMedia
            component="img"
            height="200"
            image={image}
            alt={`${title}`}
            sx={{ 
              transition: 'transform 0.3s ease-in-out',
              objectFit: 'cover',  // Ensure the image covers the area without distortion
              borderRadius: '16px 16px 0 0', // Match border radius with the card but only for top corners
            }}
          />
          <Box 
            sx={{ 
              padding: '6px ',
              mt: 1  // Add margin-top to separate the title from the image
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              {title}
            </Typography>
          </Box>
        </StyledPaper>
      </Grid>
    );
  };

const HomePage = () => {
    const [pageIndex, setPageIndex] = useState(0);

    const handlePrevClick = () => {
        setPageIndex(pageIndex > 0 ? pageIndex - 1 : 0);
    };
    const handleNextClick = () => {
        setPageIndex(pageIndex + 1);
    };

    const CarouselContainer = styled('div')(({ theme }) => ({
        position: 'relative',
        maxWidth: '100%',
        margin: 'auto',
    }));
    
    const categories = [
        { title: 'CPU', image: '/CPU.webp' },
        { title: 'GPU', image: '/GPU.webp' },
        { title: 'Motherboard', image: '/card_preview_placeholder.png' },
        { title: 'Case', image: '/Case.webp' }
    ];

    const Img = styled('img')({
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    });

    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Grid container spacing={2} justifyContent="flex-start" sx={{ mb: 4 }} >
                {componentCategories.map((category, index) => (
                    <Grid item key={index}>
                        <CategoryMenu category={category} />
                    </Grid>
                ))}
            </Grid>
            <Box sx={{
                width: '100%',
                height: '40vh',
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: 'url(/motherboard-885177_1920.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 0.9,
                    zIndex: -1
                }
            }} />
            <Typography variant="h5" sx={{ my: 4, textAlign: 'center'}}>
                Browse by Category
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                {categories.map((category, index) => (
                    <CategoryGrid key={index} title={category.title} image={category.image} />
                ))}
            </Grid>
            <Typography variant="h4" sx={{ my: 4, textAlign: 'center' }}>
                Featured Ads and Sales
            </Typography>
            <Img src="/featured_ads_placeholder.webp" />
            <Box my={6}>
                <SocialProof />
            </Box>
            <Typography variant="h5" sx={{ my: 4 }}>
                New Offerings
            </Typography>
            <CarouselContainer>
                <IconButton onClick={handlePrevClick} sx={{ position: 'absolute', left: -50, bottom: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
                    <ArrowBackIcon />
                </IconButton>
                <IconButton onClick={handleNextClick} sx={{ position: 'absolute', right: -50, bottom: '50%', transform: 'translateY(-50%)', zIndex: 1 }}>
                    <ArrowForwardIcon />
                </IconButton>
                <Grid container spacing={2}>
                    {[1, 2, 3, 4].map((item) => (
                        <Grid item xs={12} sm={6} md={3} key={item}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image="/card_preview_placeholder2.png"
                                    alt="Listing Image"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Listing Title {item}
                                    </Typography>
                                    <Typography variant="body1" color="primary.main" component="p" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                                        $100
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" variant="contained" color="primary">View</Button>
                                    <Button size="small" variant="contained" color="primary">Buy</Button>
                                    <Button size="small" variant="contained" color="primary">Build</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </CarouselContainer>
        </Container>
    );
};

export default HomePage;
