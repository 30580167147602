/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JwtDataObject } from '../models/JwtDataObject';
import type { UserLoginDataObject } from '../models/UserLoginDataObject';
import type { UserRegisterDataObject } from '../models/UserRegisterDataObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersService {
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static postRegister(
        requestBody?: UserRegisterDataObject,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/register',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns JwtDataObject OK
     * @throws ApiError
     */
    public static postLogin(
        requestBody?: UserLoginDataObject,
    ): CancelablePromise<JwtDataObject> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
