import React, { useEffect, useState } from 'react';
import { styled, Container, TextField, Button, Typography, Box, InputAdornment, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ApiError, UserLoginDataObject } from '../openapi';
import { useGetLoggedInUserQuery, useUserLoginMutation } from '../app/UsersApi';
import { toast, ToastOptions, ToastContent } from 'react-toastify';
import { Visibility, VisibilityOff } from '@mui/icons-material';


const CustomButton = styled(Button)({
    fontSize: '14px',
    display: 'inline-block',
    outline: '0',
    border: '0',
    cursor: 'pointer',
    willChange: 'box-shadow, transform',
    background: 'radial-gradient(100% 100% at 100% 10%, #1976d2 0%, #1976d2 100%)',
    boxShadow: '0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%)',
    padding: '0 2em',
    borderRadius: '0.3em',
    color: '#fff',
    height: '2.6em',
    textShadow: '0 1px 0 rgb(0 0 0 / 40%)',
    transition: 'box-shadow 0.15s ease, transform 0.15s ease',
    '&:hover': {
      boxShadow: '0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0',
      transform: 'translateY(-0.1em)',
    },
    '&:active': {
      boxShadow: 'inset 0px 0.1em 0.6em #3c4fe0',
      transform: 'translateY(0em)',
    },
  });

  
const LoginPage: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [loginUser] = useUserLoginMutation();
    const { data: user } = useGetLoggedInUserQuery();

    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        if (user) {
            navigate('/components');
        }
    }, [user, navigate]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        var userLogin: UserLoginDataObject = {
            userNameOrEmail: email,
            password: password,
        };

        try {
            await loginUser(userLogin).unwrap();
            toast.success("Login success!");
            navigate('/components');
        }
        catch (error)
        {
            console.log(error)

            toast.error("Login failed:");
        }
    };

    const handleRegister = () => {
        navigate('/register');
    };

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Welcome to TechTrader
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePasswordVisibility}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CustomButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 1 }}
                    >
                        Log In
                    </CustomButton>
                    <CustomButton
                        onClick={handleRegister}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1, mb: 11 }}
                    >
                        Sign Up
                    </CustomButton>
                </Box>
            </Box>
        </Container>
    );
};

export default LoginPage;
