import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, FormControlLabel, Checkbox, Avatar, IconButton, InputAdornment } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useUserRegisterMutation } from '../app/UsersApi';
import { UserRegisterDataObject } from '../openapi';

const CustomButton = styled(Button)({
  fontSize: '14px',
  display: 'inline-block',
  outline: '0',
  border: '0',
  cursor: 'pointer',
  willChange: 'box-shadow, transform',
  background: 'radial-gradient(100% 100% at 100% 10%, #1976d2 0%, #1976d2 100%)',
  boxShadow: '0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%)',
  padding: '0 2em',
  borderRadius: '0.3em',
  color: '#fff',
  height: '2.6em',
  textShadow: '0 1px 0 rgb(0 0 0 / 40%)',
  transition: 'box-shadow 0.15s ease, transform 0.15s ease',
  '&:hover': {
    boxShadow: '0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0',
    transform: 'translateY(-0.1em)',
  },
  '&:active': {
    boxShadow: 'inset 0px 0.1em 0.6em #3c4fe0',
    transform: 'translateY(0em)',
  },
});

const PasswordStrengthIndicator = styled(Box)({
  width: '100%',
  height: '8px',
  marginTop: '8px',
  display: 'flex',
  gap: '2px',
});

const StrengthBar = styled(Box)<{ isActive: boolean, color: string }>(({ isActive, color }) => ({
  flex: 1,
  backgroundColor: isActive ? color : '#e0e0e0',
  transition: 'background-color 0.3s',
  borderRadius: '4px',
}));

const NotificationsContainer = styled(Box)({
  width: '400px',
  height: 'auto',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
  overflow: 'hidden',
  marginTop: '8px',
  borderLeftWidth: '4px',
  borderColor: 'rgb(250 204 21)',
  borderRadius: '0.375rem',
  padding: '1rem',
});

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showStrengthIndicator, setShowStrengthIndicator] = useState(false);
  const navigate = useNavigate();
  const [strength, setStrength] = useState(0);
  const [strengthText, setStrengthText] = useState('');
  const [strengthColor, setStrengthColor] = useState('#e0e0e0');
  const [requirementsMet, setRequirementsMet] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    symbol: false,
  });

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  const evaluateStrength = (password: string) => {
    let score = 0;
    const newRequirements = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      symbol: /[^A-Za-z0-9]/.test(password),
    };

    if (newRequirements.length) score++;
    if (newRequirements.uppercase && newRequirements.lowercase) score++;
    if (newRequirements.number) score++;
    if (newRequirements.symbol) score++;
    setRequirementsMet(newRequirements);
    setStrength(score);

    switch (score) {
      case 1:
        setStrengthText('Weak');
        setStrengthColor('orange');
        break;
      case 2:
        setStrengthText('Normal');
        setStrengthColor('#FFD701');
        break;
      case 3:
        setStrengthText('Strong');
        setStrengthColor('green');
        break;
      case 4:
        setStrengthText('Very Strong');
        setStrengthColor('darkgreen');
        break;
      default:
        setStrengthText('');
        setStrengthColor('grey');
    }
  };

  const [registerUser] = useUserRegisterMutation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // TODO add check for password match
    console.log({ username, email, password, confirmPassword, termsAccepted });

    var userRegister: UserRegisterDataObject = {
        userName: username,
        email: email,
        password: password
    };

    registerUser(userRegister);
  };

  const handleFocus = () => {
    setShowStrengthIndicator(true);
  };

  const handleBlur = () => {
    if (password === '') {
      setShowStrengthIndicator(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <style>
        {`
          .slide-container {
            transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
            overflow: hidden;
          }
          .slide-container.hidden {
            max-height: 0;
            opacity: 0;
            visibility: hidden;
          }
          .slide-container.visible {
            max-height: 300px; /* Adjust based on your content height */
            opacity: 1;
            visibility: visible;
          }
        `}
      </style>
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <AccountCircleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(e) => {
              setPassword(e.target.value);
              evaluateStrength(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box className={`slide-container ${showStrengthIndicator ? 'visible' : 'hidden'}`}>
            <PasswordStrengthIndicator>
              {[0, 1, 2, 3].map((index) => (
                <StrengthBar
                  key={index}
                  isActive={strength > index}
                  color={strengthColor}
                />
              ))}
            </PasswordStrengthIndicator>
            <Typography variant="caption" sx={{ color: strengthColor, marginTop: '4px' }}>
              {strengthText}
            </Typography>
            <NotificationsContainer>
              <Typography fontWeight="bold" variant="body2" color="textSecondary">
                Password must include:
              </Typography>
              <ul style={{ paddingLeft: '20px', marginTop: '0px', marginBottom: '4px' }}>
                <li style={{ color: requirementsMet.length ? 'green' : 'grey' }}>At least 8 characters</li>
                <li style={{ color: requirementsMet.uppercase ? 'green' : 'grey' }}>Uppercase and lowercase letters</li>
                <li style={{ color: requirementsMet.number ? 'green' : 'grey' }}>A number</li>
                <li style={{ color: requirementsMet.symbol ? 'green' : 'grey' }}>A symbol (e.g., !@#$%^&)</li>
              </ul>
            </NotificationsContainer>
          </Box>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            id="confirmPassword"
            autoComplete="current-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={toggleConfirmPasswordVisibility}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ mt: showStrengthIndicator ? 2 : 3 }} // Adjust the margin top to keep spacing consistent
          />
          <FormControlLabel
            control={<Checkbox value="terms" color="primary" checked={termsAccepted} onChange={(e) => setTermsAccepted(e.target.checked)} />}
            label="I agree to the Terms and Conditions"
          />
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 1 }}
          >
            Sign Up
          </CustomButton>
          <CustomButton
            fullWidth
            onClick={() => navigate('/login')}
            sx={{ mt: 1, mb: 11 }}
          >
            Already have an account? Log in
          </CustomButton>
        </Box>
      </Box>
    </Container>
  );
};

export default Register;
