import React, { useState } from 'react';
import { Box, Grid, Button, Typography, TextField, Paper, Modal, IconButton, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import MessageIcon from '@mui/icons-material/Message';
import BuildIcon from '@mui/icons-material/Build';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';


const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ProductImage = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '800px',
  cursor: 'pointer',
  borderRadius: '10px',
  transition: 'transform 0.1s ease-in-out',
  '&:hover': {
    transform: 'scale(1.01)',
  },
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
    width: '100%',
}));

const ModalImage = styled('img')({
  width: '100%', // Adjusted to 80%
  height: 'auto',
  borderRadius: '10px',
  objectFit: 'cover',
  display: 'block',
  margin: 'auto', // Center the image horizontally
});

const ProductTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  color: theme.palette.text.primary,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0),
}));

const ProductPrice = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(0),
}));

const SellerInfo = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(1),
}));

const CustomButton = styled(Button)({
    fontSize: '14px',
    display: 'inline-block',
    outline: '0',
    border: '0',
    cursor: 'pointer',
    willChange: 'box-shadow, transform',
    background: 'radial-gradient(100% 100% at 100% 10%, #1976d2 0%, #1976d2 100%)',
    boxShadow: '0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%)',
    padding: '0 2em',
    borderRadius: '0.3em',
    color: '#fff',
    height: '2.6em',
    textShadow: '0 1px 0 rgb(0 0 0 / 40%)',
    transition: 'box-shadow 0.15s ease, transform 0.15s ease',
    '&:hover': {
      boxShadow: '0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0',
      transform: 'translateY(-0.1em)',
    },
    '&:active': {
      boxShadow: 'inset 0px 0.1em 0.6em #3c4fe0',
      transform: 'translateY(0em)',
    },
  });

  const CustomButton2 = styled(Button)(({ theme }) => ({
    fontSize: '14px',
    display: 'inline-block',
    outline: '0',
    border: '0',
    cursor: 'pointer',
    willChange: 'box-shadow, transform',
    background: theme.palette.mode === 'dark' 
      ? 'radial-gradient(100% 100% at 100% 10%, #333 0%, #333 100%)' 
      : 'radial-gradient(100% 100% at 100% 10%, #fff 0%, #fff 100%)',
    boxShadow: theme.palette.mode === 'dark' 
      ? '0px 0.01em 0.01em rgb(0 0 0 / 40%), 0px 0.3em 0.7em -0.01em rgb(0 0 0 / 30%), inset 0px -0.01em 0px rgb(0 0 0 / 50%)' 
      : '0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 20%), inset 0px -0.01em 0px rgb(58 65 111 / 50%)',
    padding: '0 2em',
    borderRadius: '0.3em',
    color: theme.palette.mode === 'dark' ? '#fff' : '#1976d2',
    height: '2.6em',
    textShadow: theme.palette.mode === 'dark' 
      ? '0 1px 0 rgb(255 255 255 / 60%)' 
      : '0 1px 0 rgb(0 0 0 / 60%)',
    transition: 'box-shadow 0.15s ease, transform 0.15s ease',
    '&:hover': {
      boxShadow: theme.palette.mode === 'dark' 
        ? '0px 0.1em 0.2em rgb(0 0 0 / 40%), 0px 0.4em 0.7em -0.1em rgb(0 0 0 / 30%), inset 0px -0.1em 0px #555' 
        : '0px 0.1em 0.2em rgb(45 35 66 / 10%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 20%), inset 0px -0.1em 0px #808080',
      transform: 'translateY(-0.1em)',
    },
    '&:active': {
      boxShadow: theme.palette.mode === 'dark' 
        ? 'inset 0px 0.1em 0.6em #555' 
        : 'inset 0px 0.1em 0.6em #808080',
      transform: 'translateY(0em)',
    },
}));

const ratingStyles = `
.rating {
  display: inline-block;
}
.rating input {
  display: none;
}
.rating label {
  float: right;
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s;
}
.rating label:before {
  content: '\\2605';
  font-size: 30px;
}
.rating input:checked ~ label,
.rating label:hover,
.rating label:hover ~ label {
  color: #1976d2;
  transition: color 0.3s;
}
`;

export default function ComponentDetails() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [reviews, setReviews] = useState([
    { user: 'Alice', text: 'Great performance and value for the price!', rating: 5 },
    { user: 'Bob', text: 'Handles all my games at high settings.', rating: 4 }
  ]);
  const [newReview, setNewReview] = useState('');
  const [newRating, setNewRating] = useState(0);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleReviewSubmit = () => {
    if (newReview && newRating) {
      setReviews([...reviews, { user: 'New User', text: newReview, rating: newRating }]);
      setNewReview('');
      setNewRating(0);
    }
  };

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  const handleWriteMessage = () => {
    navigate('/chat', { state: { product } });
  };
  

  const product = {
    id: 'prod1', // Example product ID, replace with actual product data
    title: 'GeForce RTX 2070 Super', // Product title
    price: '$300', // Product price
  };

  return (
    <Box sx={{ mt: 4, mb: 2, pr: 4 }}>
      <style>{ratingStyles}</style>
      <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="stretch">
        <Grid item xs={12} md={3.5} justifyContent="center" alignItems="stretch" sx={{ ml: 20}}>
          <StyledPaper>
            <ProductImage
              alt="Product Image"
              src="/card_preview_placeholder.png"
              onClick={handleImageClick}
            />
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={6} container direction="column" spacing={2}>
          <Grid item>
            <Item sx={{width: "70%"}} >
              <ProductTitle sx={{fontWeight: "bold"}}>GeForce RTX 2070 Super</ProductTitle>
              <ProductPrice sx={{fontWeight: "bold"}}>$300</ProductPrice>
            </Item>
          </Grid>
          <Grid item container direction="column" spacing={1}>
                      <Grid item>
                      <CustomButton
                              variant="contained"
                              fullWidth
                              sx={{
                                marginLeft: "100px",
                                  width: "50%",
                                  display: "flex",
                                  alignItems: "center",
                                  '& .MuiButton-startIcon': {
                                      marginRight: 1,
                                  },
                                  '& .MuiButton-startIcon > *:nth-of-type(1)': {
                                      fontSize: '20px',
                                  },
                              }}
                              startIcon={<AddShoppingCartIcon />}
                          >
                              Buy Directly
                          </CustomButton>
                      </Grid>
                      <Grid item>
              <CustomButton2
                variant="contained"
                sx={{
                  marginLeft: "100px",
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  '& .MuiButton-startIcon': {
                    marginRight: 1,
                  },
                  '& .MuiButton-startIcon > *:nth-of-type(1)': {
                    fontSize: '20px',
                  },
                }}
                startIcon={<MessageIcon />}
                onClick={handleWriteMessage} // Add this line to handle navigation
              >
                Write Message
              </CustomButton2>
                      </Grid>
                      <Grid item>
                          <CustomButton2
                              variant="contained"
                              fullWidth
                              sx={{
                                marginLeft: "100px",
                                  width: "50%",
                                  display: "flex",
                                  alignItems: "center",
                                  '& .MuiButton-startIcon': {
                                      marginRight: 1,
                                  },
                                  '& .MuiButton-startIcon > *:nth-of-type(1)': {
                                      fontSize: '20px',
                                  },
                              }}
                              startIcon={<SaveIcon />}
                          >
                              Save
                          </CustomButton2>
                      </Grid>
                      <Grid item>
                          <CustomButton2
                              variant="contained"
                              fullWidth
                              sx={{
                                  marginLeft: "100px",
                                  width: "50%",
                                  display: "flex",
                                  alignItems: "center",
                                  '& .MuiButton-startIcon': {
                                      marginRight: 1,
                                  },
                                  '& .MuiButton-startIcon > *:nth-of-type(1)': {
                                      fontSize: '20px',
                                  },
                              }}
                              startIcon={<ShareIcon />}
                          >
                              Share
                          </CustomButton2>
                      </Grid>
                      <Grid item>
                          <CustomButton2
                              variant="contained"
                              fullWidth
                              sx={{
                                marginLeft: "100px",
                                  width: "50%",
                                  display: "flex",
                                  alignItems: "center",
                                  '& .MuiButton-startIcon': {
                                      marginRight: 1,
                                  },
                                  '& .MuiButton-startIcon > *:nth-of-type(1)': {
                                      fontSize: '20px',
                                  },
                              }}
                              startIcon={<BuildIcon />}
                          >
                              Add to Build
                          </CustomButton2>
            </Grid>
          </Grid>
                  <Grid item>
                      <SellerInfo sx={{ width: "70%" }}>
                          <Typography variant="h6">Seller Information</Typography>
                          <Box display="flex" alignItems="center" mb={2}>
                              <Avatar sx={{ mr: 2, bgcolor: 'primary.main' }}>
                                  <AccountCircleIcon />
                              </Avatar>
                              <Box>
                                  <Typography>Name: John Doe</Typography>
                                  <Typography>Rating: 4.5/5</Typography>
                                  <Box className="rating">
                                      <input type="radio" id="star5" name="rating" value="5" />
                                      <label htmlFor="star5" title="5 stars"></label>
                                      <input type="radio" id="star4" name="rating" value="4" />
                                      <label htmlFor="star4" title="4 stars"></label>
                                      <input type="radio" id="star3" name="rating" value="3" />
                                      <label htmlFor="star3" title="3 stars"></label>
                                      <input type="radio" id="star2" name="rating" value="2" />
                                      <label htmlFor="star2" title="2 stars"></label>
                                      <input type="radio" id="star1" name="rating" value="1" />
                                      <label htmlFor="star1" title="1 star"></label>
                                  </Box>
                                  <Typography>Contact: johndoe@example.com</Typography>
                              </Box>
                          </Box>
                      </SellerInfo>
                  </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ maxWidth: '80%', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Typography variant="body1" color="textPrimary" sx={{ fontSize: '1.1rem' }}>
              The GeForce RTX 2070 Super features 8GB of GDDR6 memory and supports real-time ray tracing. It's a great choice for gaming and high-performance tasks.
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ maxWidth: '80%', margin: 'auto' }}>
            <Typography variant="subtitle1" color="textPrimary" gutterBottom>
              Memory: 8GB GDDR6
            </Typography>
            <Typography variant="subtitle1" color="textPrimary" gutterBottom>
              Clock Speed: 1605 MHz
            </Typography>
            <Typography variant="subtitle1" color="textPrimary" gutterBottom>
              Ports: 3x DisplayPort, 1x HDMI, 1x USB Type-C
            </Typography>
            <Typography variant="subtitle1" color="textPrimary" gutterBottom>
              Power Consumption: 215W
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Button
              fullWidth
              onClick={handleExpandClick}
              aria-expanded={expanded}
              endIcon={<ExpandMoreIcon />}
            >
              {expanded ? 'Hide Reviews' : 'Show Reviews'}
            </Button>
            {expanded && (
              <Grid container spacing={2} direction="column">
                {reviews.map((review, index) => (
                  <Grid item key={index}>
                    <Item>
                      <Typography variant="body2" color="textSecondary">
                        {review.text} - {review.user}
                      </Typography>
                      <Box className="rating">
                        {[...Array(5)].map((_, i) => (
                          <React.Fragment key={i}>
                            <input type="radio" id={`star${i + 1}-${index}`} name={`rating-${index}`} value={i + 1} defaultChecked={review.rating === i + 1} />
                            <label htmlFor={`star${i + 1}-${index}`} title={`${i + 1} stars`}></label>
                          </React.Fragment>
                        ))}
                      </Box>
                    </Item>
                  </Grid>
                ))}
                <Grid item>
                  <Item>
                    <Typography variant="h6">Leave a Review</Typography>
                    <TextField
                      label="Your Review"
                      multiline
                      rows={4}
                      value={newReview}
                      onChange={(e) => setNewReview(e.target.value)}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                    <Box className="rating">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <React.Fragment key={star}>
                          <input
                            type="radio"
                            id={`star${star}`}
                            name="new-rating"
                            value={star}
                            checked={newRating === star}
                            onChange={() => setNewRating(star)}
                          />
                          <label htmlFor={`star${star}`} title={`${star} star${star > 1 ? 's' : ''}`}></label>
                        </React.Fragment>
                      ))}
                    </Box>
                    <Button variant="contained" color="primary" onClick={handleReviewSubmit}>
                      Submit Review
                    </Button>
                  </Item>
                </Grid>
              </Grid>
            )}
          </Item>
        </Grid>
      </Grid>

      {/* Image Modal */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        BackdropProps={{
          onClick: handleCloseModal,
          style: { cursor: 'pointer' },
        }}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ position: 'relative', width: '40%', maxHeight: 'auto', borderRadius: '10px'}}>
          <IconButton
            onClick={handleCloseModal}
            sx={{ position: 'absolute', top: 10, right: 10, color: 'white', zIndex: 2 }}
          >
            <CloseIcon />
          </IconButton>
          <ModalImage src="/card_preview_placeholder.png" alt="Enlarged Product Image" />
        </Box>
      </Modal>
    </Box>
  );
}
