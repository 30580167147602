import React, { createContext, useState, useContext, ReactNode } from 'react';
import { ThemeProvider, createTheme, Theme } from '@mui/material/styles';

// Create a context to hold the theme information
const ThemeContext = createContext({
  toggleTheme: () => {},
  theme: createTheme(),
});

// Custom hook to use the ThemeContext
export const useToggleTheme = () => useContext(ThemeContext).toggleTheme;
export const useTheme = () => useContext(ThemeContext).theme;

// ThemeProviderWrapper Component
export const ThemeProviderWrapper = ({ children }: { children: ReactNode }) => {
  const [darkMode, setDarkMode] = useState(false);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  const theme: Theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  return (
    <ThemeContext.Provider value={{ toggleTheme, theme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
