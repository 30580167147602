import * as React from 'react';
import { AppBar, Box, Button, Toolbar, Container } from '@mui/material';
import { useNavigate, Outlet } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import MessageIcon from '@mui/icons-material/Message';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';

export default function DashboardLayout() {
  const navigate = useNavigate();

  // Define navigation buttons with their respective icons and paths
  const navigationItems = [
    { icon: <HomeIcon />, label: 'Listings', path: '/dashboard/listings' },
    { icon: <MessageIcon />, label: 'Messages', path: '/dashboard/messages' },
    { icon: <HistoryIcon />, label: 'Trade History', path: '/dashboard/history' },
    { icon: <SettingsIcon />, label: 'Account Settings', path: '/dashboard/settings' },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="inherit" elevation={1}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {navigationItems.map((item, index) => (
              <Button
                key={index}
                startIcon={item.icon}
                onClick={() => navigate(item.path)}
                sx={{ margin: '0 8px', color: 'rgba(0, 0, 0, 0.87)', textTransform: 'none' }}
              >
                {item.label}
              </Button>
            ))}
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="xl" sx={{ mt: 3 }}>
        <Outlet /> {/* This will render the child routes */}
      </Container>
    </Box>
  );
}
