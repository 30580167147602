import React from 'react';
import { Box, Grid, Button, MenuItem, TextField, InputAdornment, Typography, Breadcrumbs, Link, IconButton, useTheme } from '@mui/material';
import FiltersSidebar from './FiltersSidebar';
import ComponentCard from './ComponentCard';
import componentCategories from '../app/types/ComponentCategories';
import { ComponentFilter, CreateComponentFilter, useGetComponentsQuery } from '../app/ComponentsApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { ComponentCategory } from '../app/types/ComponentCategory';
import { bindHover, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';
import ComponentManufacturer from '../app/types/ComponentManufacturer';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { ExpandMore, Search } from '@mui/icons-material';

const CategoryMenu: React.FC<{ category: ComponentCategory }> = ({ category }) => {
    const popupState = usePopupState({
        variant: 'popover',
        popupId: `${category}-menu`,
    });

    const navigate = useNavigate();
    const theme = useTheme(); // Get the current theme

    const handleMenuItemClick = (manufacturer: ComponentManufacturer) => {
        let url = '/components';
        let filter = new ComponentFilter(category.type.name, manufacturer.name);
        url += '?' + filter.encodeAsURIQuery();
        navigate(url);
        popupState.close();
    };

    return (
        <React.Fragment>
            <Button
                variant="text"
                endIcon={<ExpandMore style={{ marginLeft: '-6px' }} />}
                style={{ 
                    color: theme.palette.text.primary, // Use theme color
                    backgroundColor: theme.palette.background.paper, // Use theme background
                }}
                {...bindHover(popupState)}
            >
                {category.type.name}
            </Button>
            <HoverMenu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                    style: {
                        backgroundColor: theme.palette.background.paper, // Use theme background
                        color: theme.palette.text.primary, // Use theme text color
                    },
                }}
                {...bindMenu(popupState)}
            >
                {category.manufacturers.map((manufacturer, index) => (
                    <MenuItem 
                        key={index} 
                        onClick={() => handleMenuItemClick(manufacturer)}
                        style={{
                            color: theme.palette.text.primary, // Use theme text color
                        }}
                    >
                        {manufacturer.name}
                    </MenuItem>
                ))}
            </HoverMenu>
        </React.Fragment>
    );
};

const ComponentsOverview: React.FC = () => {
    const location = useLocation();
    const filter = CreateComponentFilter(location);
    const { data, error } = useGetComponentsQuery(filter.asApiComponentFilter());
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = React.useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let url = '/components';
        let searchParams = new URLSearchParams(location.search);
        searchParams.set('search', searchQuery);
        url += '?' + searchParams.toString();
        navigate(url);
    };

    if (error) {
        return (
            <Box sx={{ minWidth: 275, mt: 4 }}>
                <div>
                    <p>Got error when fetching data: {String(error)}</p>
                </div>
            </Box>
        );
    }

    if (!data) {
        return (
            <Box sx={{ minWidth: 275, mt: 4 }}>
                <div>
                    <p>Loading components...</p>
                </div>
            </Box>
        );
    }

    if (data.length === 0) {
        return (
            <Box sx={{ minWidth: 275, mt: 4 }}>
                <div>
                    <p>No components found</p>
                </div>
            </Box>
        );
    }

    return (
        <Box sx={{ mt: 4, px: 2 }}>
            <Grid container spacing={2} justifyContent="flex-start" sx={{ ml: 1,  }}>
                {componentCategories.map((category, index) => (
                    <Grid item key={index}>
                        <CategoryMenu category={category} />
                    </Grid>
                ))}
            </Grid>
            <Breadcrumbs aria-label="breadcrumb" separator=">" sx={{ fontWeight: 'bold', mt: 2, mb:1, ml: 5 }}>
                <Link underline="hover" color="primary" href="/" sx={{ fontWeight: 'bold' }}>
                    Homepage
                </Link>
                <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                    Components
                </Typography>
            </Breadcrumbs>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <FiltersSidebar />
                </Grid>
                <Grid item xs={12} md={9}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 , mr:5}}>
                        <form onSubmit={handleSearchSubmit} style={{ width: 'auto', paddingRight: '20px' }}>
                            <TextField
                                variant="outlined"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton type="submit">
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        padding: '4px 4px',
                                        fontSize: '1.25rem', // Adjust font size to make the input slimmer
                                        height: '40px', // Set the height of the input field
                                    },
                                }}
                                sx={{
                                    borderRadius: '80px',
                                    minWidth: '150px',
                                    maxWidth: '200px',
                                    height: '32px', // Set the height of the TextField
                                }}
                            />
                        </form>
                    </Box>
                    <Box sx={{ minWidth: 275, mt: 4, pr: 7, ml: -20}}>
                        <Grid container spacing={2}>
                            {data.map((componentData, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <ComponentCard componentData={componentData} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ComponentsOverview;
