// src/components/DashboardListings.js
import * as React from 'react';
import { Box, Typography, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Example listings data
const listings = [
  { id: 1, title: 'NVIDIA GTX 1080', description: 'Lightly used graphics card.' },
  // Add more listings as needed
];

export default function DashboardListings() {
  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Your Listings
      </Typography>
      <Paper elevation={1}>
        <List>
          {listings.map((listing) => (
            <ListItem key={listing.id} divider>
              <ListItemText primary={listing.title} secondary={listing.description} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit">
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
}
