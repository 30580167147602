import React, { useState, useEffect } from 'react';
import { Box, IconButton, TextField, List, ListItem, Typography, Paper, Divider, Grid, Avatar, ListItemText } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';


interface Message {
  id: number;
  text: string;
  senderId: string;
  timestamp: Date;
}

interface Product {
  id: string;
  title: string;
  price: string;
}

interface Conversation {
  id: string;
  title: string;
  lastMessage: string;
  product: Product;
}

const ChatInterface = () => {
  const location = useLocation();
  const passedProduct = location.state?.product || null;

  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [conversations, setConversations] = useState<Conversation[]>([
    { 
      id: 'conv1', 
      title: 'Jeremia Doe', 
      lastMessage: 'Hello there!', 
      product: { id: 'prod1', title: 'GeForce RTX 3070', price: '$500' } 
    },
    { 
      id: 'conv2', 
      title: 'Jane Smith', 
      lastMessage: 'Are you available?', 
      product: { id: 'prod2', title: 'Ryzen 9 5900X', price: '$450' } 
    }
  ]);
  const [activeConversation, setActiveConversation] = useState<string>('conv1');

  useEffect(() => {
    if (passedProduct) {
      const existingConversation = conversations.find(conv => conv.product?.id === passedProduct.id);
      
      if (existingConversation) {
        setActiveConversation(existingConversation.id);
      } else {
        const newConv: Conversation = {
          id: `conv${conversations.length + 1}`,
          title: `Chat about ${passedProduct.title}`,
          lastMessage: '',
          product: passedProduct,
        };
        setConversations(prevConversations => {
          const updatedConversations = [...prevConversations, newConv];
          setActiveConversation(newConv.id);
          return updatedConversations;
        });
      }
    }
  }, [passedProduct, conversations]);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const message: Message = {
        id: messages.length + 1,
        text: newMessage,
        senderId: '12345', // Assuming '12345' is the current user ID
        timestamp: new Date(),
      };
      setMessages([...messages, message]);
      setNewMessage('');

      // Send message to backend
    }
  };

  const selectConversation = (id: string) => {
    setActiveConversation(id);
    // Load messages for selected conversation
  };

  const activeConvData = conversations.find(conv => conv.id === activeConversation);

  return (
    <Grid container spacing={2} sx={{ height: '100vh', maxWidth: 1200, margin: 'auto' }}>
      <Grid item xs={12} md={3} sx={{ borderRight: 1, borderColor: 'divider' }}>
        <List>
          {conversations.map((conv) => (
            <ListItem
              button
              key={conv.id}
              selected={conv.id === activeConversation}
              onClick={() => selectConversation(conv.id)}
            >
              <ListItemText primary={conv.title} secondary={conv.lastMessage} />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12} md={9}>
  {activeConvData && (
    <Paper sx={{ maxHeight: '90vh', overflow: 'auto', padding: 2 }}>
      {/* Product Information */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">
          <Link to="http://localhost:3000/components/66b73af3a163f81046ca41fe" style={{ textDecoration: 'none', color: 'inherit' }}>
            {activeConvData.product.title}
          </Link>
        </Typography>
        <Typography color="text.secondary">{activeConvData.product.price}</Typography>
      </Box>

            {/* Chat Messages */}
            <List sx={{ maxHeight: '65vh', overflow: 'auto', mb: 2 }}>
              {messages.map((message) => (
                <ListItem key={message.id} sx={{ justifyContent: message.senderId === '12345' ? 'flex-end' : 'flex-start' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: message.senderId === '12345' ? 'row-reverse' : 'row',
                      alignItems: 'center',
                      maxWidth: '70%',
                      marginLeft: '8px', // For other user's messages
                      marginRight:'8px', // For your messages
                    }}
                  >
                    <Avatar sx={{ bgcolor: message.senderId === '12345' ? 'primary.main' : 'secondary.main' }}>
                      {message.senderId === '12345' ? 'Me' : 'J'}
                    </Avatar>
                    <Box
                      sx={{
                        bgcolor: message.senderId === '12345' ? 'primary.light' : 'grey.300',
                        color: message.senderId === '12345' ? 'primary.contrastText' : 'text.primary',
                        borderRadius: 2,
                        p: 1,
                        ml: 1,
                        // Adjust these margins to create space
                        marginLeft: message.senderId === '12345' ? 0 : '16px', // Increase this value to add more space for the other user's messages
                        marginRight: message.senderId === '12345' ? '16px' : 0, // Increase this value to add more space for your messages
                      }}
                    >
                      <Typography sx={{ whiteSpace: 'pre-wrap' }}>{message.text}</Typography>
                      <Typography variant="caption" sx={{ display: 'block', textAlign: message.senderId === '12345' ? 'right' : 'left' }}>
                        {new Date(message.timestamp).toLocaleTimeString()}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
            <Divider />

            {/* Message Input */}
            <Box component="form" sx={{ display: 'flex', alignItems: 'center', gap: 1, padding: 1 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Type a message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                autoComplete="off"
                multiline // Allow multiple lines
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault(); // Prevent new line or default form submission
                    handleSendMessage(); // Send the message
                  }
                }}
              />
              <IconButton color="primary" onClick={handleSendMessage}>
                <SendIcon />
              </IconButton>
            </Box>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export default ChatInterface;
