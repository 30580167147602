import React, { useState } from 'react';
import { Container, Grid, Button, Typography, Link, Menu, MenuItem } from '@mui/material';


const HomePageOld = () => {
  const [cpuMenuAnchorEl, setCpuMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [gpuMenuAnchorEl, setGpuMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [motherboardsMenuAnchorEl, setMotherboardsMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [peripheralsMenuAnchorEl, setPeripheralsMenuAnchorEl] = useState<null | HTMLElement>(null);

  // Handlers to open each menu
  const handleCpuMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCpuMenuAnchorEl(event.currentTarget);
  };
  const handleGpuMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setGpuMenuAnchorEl(event.currentTarget);
  };
  const handleMotherboardsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMotherboardsMenuAnchorEl(event.currentTarget);
  };
  const handlePeripheralsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPeripheralsMenuAnchorEl(event.currentTarget);
  };

  // Handlers to close each menu
  const handleClose = () => {
    setCpuMenuAnchorEl(null);
    setGpuMenuAnchorEl(null);
    setMotherboardsMenuAnchorEl(null);
    setPeripheralsMenuAnchorEl(null);
  };


  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* Product Categories Links as Dropdown Menus */}
      <Grid container spacing={2} justifyContent="flex-end">
        {/* CPU Menu */}
        <Grid item>
          <Button color="inherit" onClick={handleCpuMenuClick} sx={{
            fontWeight: 'bold',
            borderRadius: '20px',
            ':hover': {
              backgroundColor: 'rgba(0, 123, 255, 0.1)' // Lighten on hover
            }
          }}
          >CPU</Button>
          <Menu
            id="cpu-menu"
            anchorEl={cpuMenuAnchorEl}
            keepMounted
            open={Boolean(cpuMenuAnchorEl)}
            onClose={handleClose}
          >
            {/* List CPU manufacturers here */}
            <MenuItem onClick={handleClose}>Intel</MenuItem>
            <MenuItem onClick={handleClose}>AMD</MenuItem>
          </Menu>
        </Grid>
        {/* GPU Menu */}
        <Grid item>
                  <Link href="/components" variant="body2">
            <Button color="inherit" onClick={handleGpuMenuClick}sx={{
            fontWeight: 'bold',
            borderRadius: '20px',
            ':hover': {
              backgroundColor: 'rgba(0, 123, 255, 0.1)' // Lighten on hover
            }
          }}>GPU</Button>
            <Menu
              id="gpu-menu"
              anchorEl={gpuMenuAnchorEl}
              keepMounted
              open={Boolean(gpuMenuAnchorEl)}
              onClose={handleClose}
            >
              {/* List GPU manufacturers here */}
              <MenuItem onClick={handleClose}>NVIDIA</MenuItem>
              <MenuItem onClick={handleClose}>AMD</MenuItem>
              {/* Add more as needed */}
            </Menu>
          </Link>
        </Grid>
        {/* Motherboards Menu */}
        <Grid item>
          <Button color="inherit" onClick={handleMotherboardsMenuClick}sx={{
            fontWeight: 'bold',
            borderRadius: '20px',
            ':hover': {
              backgroundColor: 'rgba(0, 123, 255, 0.1)' // Lighten on hover
            }
          }}>Motherboards</Button>
          <Menu
            id="motherboards-menu"
            anchorEl={motherboardsMenuAnchorEl}
            keepMounted
            open={Boolean(motherboardsMenuAnchorEl)}
            onClose={handleClose}
          >
            {/* List Motherboard manufacturers here */}
            <MenuItem onClick={handleClose}>ASUS</MenuItem>
            <MenuItem onClick={handleClose}>MSI</MenuItem>
            <MenuItem onClick={handleClose}>Gigabyte</MenuItem>
          </Menu>
        </Grid>
        {/* Peripherals Menu */}
        <Grid item>
          <Button color="inherit" onClick={handlePeripheralsMenuClick}sx={{
            fontWeight: 'bold',
            borderRadius: '20px',
            ':hover': {
              backgroundColor: 'rgba(0, 123, 255, 0.1)' // Lighten on hover
            }
          }}>Peripherals</Button>
          <Menu
            id="peripherals-menu"
            anchorEl={peripheralsMenuAnchorEl}
            keepMounted
            open={Boolean(peripheralsMenuAnchorEl)}
            onClose={handleClose}
          >
            {/* List Peripheral manufacturers here */}
            <MenuItem onClick={handleClose}>Logitech</MenuItem>
            <MenuItem onClick={handleClose}>Razer</MenuItem>
            <MenuItem onClick={handleClose}>Corsair</MenuItem>
          </Menu>
        </Grid>
      </Grid>
      {/* Ads and Sales Announcements */}
      <Typography variant="h4" sx={{ my: 4, textAlign: 'center' }}>
        Featured Ads and Sales
      </Typography>
      <div style={{ height: '50vh', backgroundColor: '#f0f0f0' }}> {/* Placeholder for ads */}</div>

      {/* Horizontal Card Overview for New Offerings */}
      <Typography variant="h5" sx={{ my: 4 }}>
        New Offerings
      </Typography>
      <Grid container spacing={2}>
        {/* Placeholder for cards */}
      </Grid>
    </Container>
  );
};

export default HomePageOld;

