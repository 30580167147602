/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuildDataObject } from '../models/BuildDataObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BuildsService {
    /**
     * @param userId
     * @returns BuildDataObject OK
     * @throws ApiError
     */
    public static getApiBuildsCurrent(
        userId?: string,
    ): CancelablePromise<BuildDataObject> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/builds/current',
            headers: {
                'UserId': userId,
            },
        });
    }
    /**
     * @param buildId
     * @param userId
     * @returns BuildDataObject OK
     * @throws ApiError
     */
    public static postApiBuildsCurrent(
        buildId: string,
        userId?: string,
    ): CancelablePromise<BuildDataObject> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/builds/current/{buildId}',
            path: {
                'buildId': buildId,
            },
            headers: {
                'UserId': userId,
            },
        });
    }
    /**
     * @param userId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static postApiBuilds(
        userId?: string,
        requestBody?: BuildDataObject,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/builds',
            headers: {
                'UserId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param userId
     * @returns BuildDataObject OK
     * @throws ApiError
     */
    public static getApiBuilds(
        userId?: string,
    ): CancelablePromise<Array<BuildDataObject>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/builds',
            headers: {
                'UserId': userId,
            },
        });
    }
    /**
     * @param buildId
     * @param userId
     * @returns void
     * @throws ApiError
     */
    public static getApiBuilds1(
        buildId: string,
        userId?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/builds/{buildId}',
            path: {
                'buildId': buildId,
            },
            headers: {
                'UserId': userId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * @param componentId
     * @param userId
     * @returns BuildDataObject OK
     * @throws ApiError
     */
    public static postApiBuildsCurrentComponents(
        componentId: string,
        userId?: string,
    ): CancelablePromise<BuildDataObject> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/builds/current/components/{componentId}',
            path: {
                'componentId': componentId,
            },
            headers: {
                'UserId': userId,
            },
        });
    }
    /**
     * @param componentId
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteCurrent(
        componentId: string,
        userId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/current/{componentId}',
            path: {
                'componentId': componentId,
            },
            headers: {
                'UserId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteCurrent1(
        userId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/current',
            headers: {
                'UserId': userId,
            },
        });
    }
}
