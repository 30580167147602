import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { ApiError, JwtDataObject, OpenAPI, UserLoginDataObject, UserRegisterDataObject, UsersService } from '../openapi';

import Cookies from 'js-cookie';

let fetchQuery: any = fetchBaseQuery({ baseUrl: OpenAPI.BASE })

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: fetchQuery,
    tagTypes: ['User'],
    endpoints: (builder) => ({
        getLoggedInUser: builder.query<string, void>({
            queryFn: async () => {
                console.log("Refreshing user jwt");

                let jwt = Cookies.get('jwt');

                if (!jwt)
                {
                    console.log("stored invalid jwt");
                    return { data: undefined, error: "user not logged in" }
                }

                return { data: jwt }
            },
            providesTags: ["User"]
        }),
        userLogout: builder.mutation<string, void>({
            queryFn: async () => {

                console.log("Logging out user");

                Cookies.remove('jwt');

                return { data:"" }
            },
            invalidatesTags: ["User"]
        }),
        userRegister: builder.mutation<string, UserRegisterDataObject>({
            queryFn: async (userRegisterDataObject) => {

                await UsersService.postRegister(userRegisterDataObject);

                return { data:"" }
            },
        }),
        userLogin: builder.mutation<JwtDataObject, UserLoginDataObject>({
            queryFn: async (userLoginDataObject) => {
                try {
                    console.log("Logging in user");

                    Cookies.remove('jwt');

                    let jwt = await UsersService.postLogin(userLoginDataObject);

                    console.log(jwt);

                    // TODO bind invalidation logic between route and cookies state
                    Cookies.set('jwt', jwt.token!, { 
                        expires: 30,
                        secure: true, // Use Secure attribute
                        sameSite: 'Strict', // Use SameSite attribute
                    });

                    return { data: jwt }
                }
                // NOTE need to do it like this, createApi unfortunately swallows custom error parameters
                // FIXME extract into separate function
                catch (error)
                {
                    let body = (error as ApiError).body;

                    return { error: body }
                }

            },
            invalidatesTags: ["User"]
        }),
    }),
})


export const { useUserRegisterMutation, useUserLoginMutation, useGetLoggedInUserQuery, useUserLogoutMutation } = usersApi