import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { OfferDataObject, OffersService, OpenAPI, PriceDataObject } from '../openapi';

let fetchQuery: any = fetchBaseQuery({ baseUrl: OpenAPI.BASE })

const userId = "6676889e8a69134f8735ff1a";

export interface CounterOffer {
    componentId: string,
    amount: number
}

export const offersApi = createApi({
    reducerPath: 'offersApi',
    baseQuery: fetchQuery,
    tagTypes: ['Offer'],
    endpoints: (builder) => ({
        getOffers: builder.query<OfferDataObject, void>({
            queryFn: async () => {
                const data = await OffersService.getApiOffersCurrent(userId);

                return { data: data }
            },
            providesTags: [{ type: 'Offer' }]
        }),
        addComponentsFromBuild: builder.mutation<OfferDataObject, string>({
            queryFn: async (buildId) => {
                var result = await OffersService.putApiOffersCurrentComponentsFromBuild(buildId, userId)

                return { data: result }
            },
            invalidatesTags: [{ type: 'Offer' }]
        }),
        clearOffers: builder.mutation<string, void>({
            queryFn: async () => {
                await OffersService.deleteApiOffersCurrent(userId)

                return { data: "ELLO" }
            },
            invalidatesTags: [{ type: 'Offer' }]
        }),
        proposeCounterOffer: builder.mutation<OfferDataObject, CounterOffer>({
            queryFn: async (counterOffer) => {
                
                let dto: PriceDataObject =
                {
                    currency: "EUR",
                    amount: counterOffer.amount
                };

                let result = await OffersService.putApiOffersCurrentCounteroffer(counterOffer.componentId, userId, dto)

                return { data: result }
            },
            invalidatesTags: [{ type: 'Offer' }]
        }),
        addComponentToCard: builder.mutation<string, string>({
            queryFn: async (componentId) => {
                
                let result = await OffersService.putApiOffersCurrentComponents(componentId, userId)

                return { data: result }
            },
            invalidatesTags: [{ type: 'Offer' }]
        }),
    }),
})

export const { useGetOffersQuery, useAddComponentsFromBuildMutation, useClearOffersMutation, useProposeCounterOfferMutation, useAddComponentToCardMutation } = offersApi