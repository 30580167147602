import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Avatar, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Person2TwoTone, ChatBubbleTwoTone, FavoriteTwoTone } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useGetLoggedInUserQuery, useUserLogoutMutation } from '../app/UsersApi';
import { useToggleTheme } from './ThemeContext';

const Header = () => {

    const { data: user } = useGetLoggedInUserQuery();
    const [logoutUser] = useUserLogoutMutation();

    const navigate = useNavigate();
    const toggleTheme = useToggleTheme();

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <div
                        color="inherit"
                        style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        onClick={() => navigate("/")}
                    >
                        <Avatar
                            src="/logo_round.jpg" // Adjust the path to your image
                            alt="TechTrader Logo"
                            sx={{ width: 50, height: 50, marginRight: 1 }} // Adjust size as needed
                        />
                        <Typography
                            variant="h5"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, fontFamily: 'Dubai' }}
                        >
                            TECHTRADER
                        </Typography>
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex' }}>
                        <Button onClick={() => navigate("/components")} color="inherit">Components</Button>
                        <Button onClick={() => navigate("/upload")} color="inherit">Upload</Button>
                        <Button onClick={() => navigate("/build")} color="inherit">Build</Button>
                        <Button onClick={() => navigate("/cart")} color="inherit">Cart</Button>
                        <IconButton onClick={() => navigate("/dev")} color="inherit">
                            <FavoriteTwoTone />
                        </IconButton>
                        <IconButton onClick={() => navigate("/chat")} color="inherit">
                            <ChatBubbleTwoTone sx={{ transform: 'scaleX(-1)' }} />
                        </IconButton>
                        <IconButton onClick={() => navigate("/dashboard")} color="inherit">
                            <Person2TwoTone />
                        </IconButton>
                        {user === undefined ?
                            (<Button
                                onClick={() => navigate("/login")}
                                sx={{
                                    color: 'white',
                                    borderColor: 'white',
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderRadius: '20px',
                                    textTransform: 'none',
                                }}
                            >
                                Log In
                            </Button>)
                            :
                            (<Button
                                onClick={() => logoutUser()}
                                sx={{
                                    color: 'white',
                                    borderColor: 'white',
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderRadius: '20px',
                                    textTransform: 'none',
                                }}
                            >
                                Log Out
                            </Button>)
                        }
                        {/* Dark Mode Toggle Switch */}
                        <input
                            type="checkbox"
                            className="theme-checkbox"
                            onChange={toggleTheme} // Use the toggleTheme function to switch themes
                            aria-label="Toggle Dark Mode"
                        />
                    </div>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
