import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';

const CreateListingsForm3 = () => {
  // State for the selected file
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // Handle file selection
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    } else {
      setSelectedFile(null);
    }
  };

  // Access the current theme (light/dark mode)
  const theme = useTheme();

  const customFileUploadStyle: React.CSSProperties = {
    height: '200px',
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px dashed ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    padding: '1.5rem',
    borderRadius: '10px',
    boxShadow: '0px 48px 35px -48px rgba(0,0,0,0.1)',
  };

  const iconStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const textStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 400,
    color: theme.palette.text.primary,
  };

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh', // Takes up the full height of the viewport
    backgroundColor: theme.palette.background.default, // Optional: Set background color to match theme
  };

  return (
    <div style={containerStyle}>
      {/* Custom file upload button */}
      <label htmlFor="file-upload" style={customFileUploadStyle}>
        <div style={iconStyle}>
          {/* Reference the SVG from the public folder */}
          <img src="/GPU.svg" alt="GPU Icon" width="100%" height="auto" />
        </div>
        <div style={textStyle}>
          <span>{selectedFile ? selectedFile.name : 'Select GPU'}</span>
        </div>
      </label>

      {/* Hidden file input */}
      <input
        id="file-upload"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: 'none' }} // Hides the default file input
      />
    </div>
  );
};

export default CreateListingsForm3;
