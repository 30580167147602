import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { BuildDataObject, BuildsService, ComponentDataObject, ComponentsService, OpenAPI } from '../openapi';

let fetchQuery: any = fetchBaseQuery({ baseUrl: OpenAPI.BASE })

const userId = "6676889e8a69134f8735ff1a";

export const buildsApi = createApi({
    reducerPath: 'buildsApi',
    baseQuery: fetchQuery,
    tagTypes: ['Build'],
    endpoints: (builder) => ({
        getBuilds: builder.query<Array<BuildDataObject>, void>({
            queryFn: async () => {
                const data = await BuildsService.getApiBuilds(userId)

                return { data }
            },
            providesTags: [{ type: 'Build', id: 'CURRENT' }] // TODO better tags
        }),
        getCurrentBuild: builder.query<BuildDataObject | undefined, void>({
            queryFn: async () => {
                try {
                    const data = await BuildsService.getApiBuildsCurrent(userId)

                    return { data }
                }
                catch {
                    return { data: undefined }
                }
            },
            providesTags: [{ type: 'Build', id: 'CURRENT' }]
        }),
        getComponentsForCurrentBuild: builder.query<Array<ComponentDataObject>, void>({
            queryFn: async () => {
                const build: BuildDataObject = await BuildsService.getApiBuildsCurrent(userId)

                let results: Array<ComponentDataObject> = [];

                if (build.components) {
                    const promises = build.components.map(c => ComponentsService.getApiComponents1(c.componentId!));

                    results = await Promise.all(promises);
                }

                return { data: results }
            },
            providesTags: [{ type: 'Build', id: 'CURRENT' }]
        }),
        addNewBuild: builder.mutation<string, BuildDataObject>({
            queryFn: async (buildDto) => {
                await BuildsService.postApiBuilds(userId, buildDto)

                return { data: "ELLO" }
            },
            invalidatesTags: [{ type: 'Build', id: 'CURRENT' }]
        }),
        addComponentToCurrentBuild: builder.mutation<string, string>({
            queryFn: async (componentId) => {
                await BuildsService.postApiBuildsCurrentComponents(componentId, userId)

                return { data: "ELLO" }
            },
            invalidatesTags: [{ type: 'Build', id: 'CURRENT' }]
        }),
        removeComponentFromCurrentBuild: builder.mutation<string, string>({
            queryFn: async (componentId) => {

                await BuildsService.deleteCurrent(componentId, userId)

                return { data: "ELLO" }
            },
            invalidatesTags: [{ type: 'Build', id: 'CURRENT' }]
        }),
        deleteBuild: builder.mutation<string, void>({
            queryFn: async () => {

                await BuildsService.deleteCurrent1(userId)

                return { data: "ELLO" }
            },
            invalidatesTags: [{ type: 'Build', id: 'CURRENT' }]
        }),
        setCurrentBuild: builder.mutation<string, string>({
            queryFn: async (buildId) => {

                await BuildsService.postApiBuildsCurrent(buildId, userId)

                return { data: "ELLO" }
            },
            invalidatesTags: [{ type: 'Build', id: 'CURRENT' }]
        }),
    }),
})


export const { useGetCurrentBuildQuery, useAddComponentToCurrentBuildMutation, useRemoveComponentFromCurrentBuildMutation, useGetComponentsForCurrentBuildQuery, useDeleteBuildMutation, useAddNewBuildMutation, useGetBuildsQuery, useSetCurrentBuildMutation } = buildsApi