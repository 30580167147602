import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField, Typography, CircularProgress, Alert, Avatar } from '@mui/material';

function isValidEmail(email: string): boolean {
  return /\S+@\S+\.\S+/.test(email);
}

const ProfileInformation: React.FC = () => {
  const [userInfo, setUserInfo] = useState({
    name: 'John Doe',
    email: 'johndoe@example.com',
    newPassword: '',
    confirmPassword: '',
  });
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProfilePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setProfilePicture(event.target.files[0]);
    }
  };

  const handleSave = async () => {
    if (!isValidEmail(userInfo.email)) {
      setError('Please enter a valid email address.');
      return;
    }
    if (userInfo.newPassword !== userInfo.confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      // Simulate an API call
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setSuccess('Profile updated successfully!');
      // Reset password fields after successful update
      setUserInfo((prevState) => ({
        ...prevState,
        newPassword: '',
        confirmPassword: '',
      }));
    } catch (error) {
      setError('An error occurred while saving your profile.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Account Settings
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="profile-picture-input"
          type="file"
          onChange={handleProfilePictureChange}
        />
        <label htmlFor="profile-picture-input">
          <Avatar
            src={profilePicture ? URL.createObjectURL(profilePicture) : undefined}
            sx={{ width: 56, height: 56, cursor: 'pointer' }}
          />
        </label>
        <Box component="form" noValidate sx={{ mt: 3 }}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="name"
                name="name"
                required
                fullWidth
                id="name"
                label="Full Name"
                value={userInfo.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={userInfo.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                id="newPassword"
                autoComplete="new-password"
                value={userInfo.newPassword}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="confirmPassword"
                label="Confirm New Password"
                type="password"
                id="confirmPassword"
                value={userInfo.confirmPassword}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Save Changes'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ProfileInformation;
