import { ComponentCategory } from "./ComponentCategory";

// TODO[epic=7] remove this
const componentCategories: ComponentCategory[] = [
    {
        type: { name: "CPU" },
        manufacturers: [
            { name: "Intel" },
            { name: "AMD" }
        ]
    },
    {
        type: { name: "GPU" },
        manufacturers: [
            { name: "Nvidia" },
            { name: "AMD" }
        ]
    },
    {
        type: { name: "Motherboard" },
        manufacturers: [
            { name: "ASUS" },
            { name: "MSI" },
            { name: "Gigabyte" }
        ]
    },
    {
        type: { name: "Peripheral" },
        manufacturers: [
            { name: "Logitech" },
            { name: "Razor" },
            { name: "Corsair" }
        ]
    }
];

export default componentCategories;