/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComponentDataObject } from '../models/ComponentDataObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ComponentsService {
    /**
     * @param type
     * @param manufacturer
     * @returns ComponentDataObject OK
     * @throws ApiError
     */
    public static getApiComponents(
        type?: string,
        manufacturer?: string,
    ): CancelablePromise<Array<ComponentDataObject>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/components',
            query: {
                'type': type,
                'manufacturer': manufacturer,
            },
        });
    }
    /**
     * @param requestBody
     * @returns ComponentDataObject OK
     * @throws ApiError
     */
    public static postApiComponents(
        requestBody?: ComponentDataObject,
    ): CancelablePromise<ComponentDataObject> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/components',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns ComponentDataObject OK
     * @throws ApiError
     */
    public static getApiComponents1(
        id: string,
    ): CancelablePromise<ComponentDataObject> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/components/{id}',
            path: {
                'id': id,
            },
        });
    }
}
