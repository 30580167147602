import React from 'react';

const styles = `
  .card {
    width: 254px;
    height: 254px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px 30px; /* Adjust padding here */
    background-color: red;
    border-radius: 10px;
    border: none;
    color: white;
    position: relative;
    cursor: pointer;
    font-weight: 900;
    transition-duration: .2s;
    background: linear-gradient(0deg, #000, #272727);
    margin: 0 auto; /* Center the card horizontally */
  }

  .card:before, .card:after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    border-radius: 10px;
    background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
        #0000ff, #00ff00,#ffff00, #ff0000);
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: steam 20s linear infinite;
  }

  @keyframes steam {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  .card:after {
    filter: blur(100px);
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ensure the container takes full height */
    padding: 20px; /* Add some padding if needed */
  }
`;

const Card = () => (
  <div className="container">
    <style>{styles}</style>
    <div className="card">
      <div className="content">
        <div className="heading"></div>
        <img src="/CPU.svg" alt="CPU Icon" width="100%" height="auto" />
        <div className="para"></div>
      </div>
    </div>
  </div>
);

export default Card;
