import React, { useState, useEffect } from 'react';
import { Container, Typography, ListItem, ListItemText, Card, CardContent, TextField, IconButton, Button, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Send from '@mui/icons-material/Send';
import Close from '@mui/icons-material/Close';
import { CounterOffer, useClearOffersMutation, useGetOffersQuery, useProposeCounterOfferMutation } from '../app/OffersApi';
import { ComponentWithOfferMetadataDataObject } from '../openapi';

const Cart: React.FC = () => {
    const { data: offer } = useGetOffersQuery();
    const [sendProposal, { data: sendProposalData }] = useProposeCounterOfferMutation();
    const [proposals, setProposals] = useState<Record<string, number>>({});
    const [statuses, setStatuses] = useState<Record<string, boolean>>({});
    const [readyToCheckout, setReadyToCheckout] = useState<boolean>(false);
    const [clearOffers] = useClearOffersMutation();

    useEffect(() => {
        if (offer && offer.components) {
            const initialProposals: Record<string, number> = {};
            const initialStatuses: Record<string, boolean> = {};
            let initialReadyToCheckout = false;

            offer.components.forEach(componentMetadata => {
                initialProposals[componentMetadata.componentId!] = componentMetadata.bestOffer.amount;
                initialStatuses[componentMetadata.componentId!] = componentMetadata.counterOfferAccepted;
                initialReadyToCheckout = componentMetadata.counterOfferAccepted;
            });

            setProposals(initialProposals);
            setStatuses(initialStatuses);
            setReadyToCheckout(initialReadyToCheckout);
        }
    }, [offer]);

    useEffect(() => {
        if (sendProposalData && sendProposalData.components) {
            const initialProposals: Record<string, number> = {};
            const initialStatuses: Record<string, boolean> = {};
            let initialReadyToCheckout = false;

            sendProposalData.components.forEach(componentMetadata => {
                initialProposals[componentMetadata.componentId!] = componentMetadata.bestOffer.amount;
                initialStatuses[componentMetadata.componentId!] = componentMetadata.counterOfferAccepted;
                initialReadyToCheckout = componentMetadata.counterOfferAccepted;
            });

            setProposals(initialProposals);
            setStatuses(initialStatuses);
            setReadyToCheckout(initialReadyToCheckout);
        }
    }, [sendProposalData]);

    const handleOfferChange = (componentMetadata: ComponentWithOfferMetadataDataObject, price: number) => {
        let id = componentMetadata.componentId!;
        setProposals(prev => ({ ...prev, [id]: price }));
    };

    const handleSendProposal = (componentMetadata: ComponentWithOfferMetadataDataObject) => {
        let id = componentMetadata.componentId!;
        let newValue = proposals[id] || componentMetadata.bestOffer.amount;
        let proposal: CounterOffer = { componentId: id, amount: newValue };
        sendProposal(proposal);
    };

    const renderComponentStatus = (status: boolean) => {
        return status ? <CheckCircleIcon sx={{ color: 'green' }} /> : <CancelIcon sx={{ color: 'red' }} />;
    };

    const handleRemove = (componentId: string) => {
        // Placeholder for remove functionality
        console.log('Remove component with id:', componentId);
    };

    if (!offer) return <p>Loading.</p>;

    if (!offer.components || offer.components.length === 0) return <p>Your cart is empty. Feel free to add components from build overview or components view.</p>;

    const totalSum = offer.components.reduce((sum, componentMetadata) => sum + (proposals[componentMetadata.componentId!] || componentMetadata.bestOffer.amount), 0);

    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
                Your Cart
            </Typography>

            {offer.components.map((componentMetadata, index) => (
                <Card key={index} sx={{ marginBottom: 2 }}>
                    <CardContent>
                        <ListItem>
                            <ListItemText
                                primary={componentMetadata.component.identifier}
                                secondary={`Price: ${componentMetadata.component.price.amount} $, Seller: ${componentMetadata.component.creator.userName}`}
                            />
                            {renderComponentStatus(statuses[componentMetadata.componentId!])}
                            <TextField
                                label="Proposed Price"
                                type="number"
                                variant="outlined"
                                size="small"
                                sx={{ marginLeft: 2, width: '120px' }}
                                value={proposals[componentMetadata.componentId!] || componentMetadata.bestOffer.amount}
                                onChange={e => handleOfferChange(componentMetadata, Number(e.target.value))}
                            />
                            <IconButton
                                color="primary"
                                onClick={() => handleSendProposal(componentMetadata)}
                                sx={{ marginLeft: 1 }}
                            >
                                <Send />
                            </IconButton>
                            <IconButton
                                onClick={() => handleRemove(componentMetadata.componentId!)}
                                sx={{ marginLeft: 1, color: 'grey' }}
                            >
                                <Close />
                            </IconButton>
                        </ListItem>
                    </CardContent>
                </Card>
            ))}

            <Box sx={{ borderTop: '1px solid rgba(128, 128, 128, 0.3)',mt: 4, textAlign: 'right' }}>
                <Typography color="primary" variant="h5" sx={{ fontWeight: 'bold' }}>
                    Total Sum: ${totalSum.toFixed(2)}
                </Typography>
            </Box>

            <Box sx={{ mt: 4, textAlign: 'center' }}>
                <Button variant="contained" color="primary" sx={{ ml: 2 }} onClick={() => clearOffers()}
                    disabled={!readyToCheckout}
                >
                    Checkout
                </Button>
            </Box>
        </Container>
    );
};

export default Cart;
