import React from 'react';
import { Box, Container, Grid, Link, Typography, useTheme } from '@mui/material';

const Footer = () => {
  const theme = useTheme(); // Get the current theme (light/dark)

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.primary.main,
        color: 'white',
        mt: 4,
        py: 3,
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ fontFamily: 'Dubai' }}>About TechTrader</Typography>
            <Link href="#" color="inherit" sx={{ fontFamily: 'Dubai', textDecoration: 'none' }}>Learn More</Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ fontFamily: 'Dubai' }}>Customer Service</Typography>
            <Link href="#" color="inherit" sx={{ fontFamily: 'Dubai', textDecoration: 'none' }}>Contact Us</Link>
            <Typography variant="h6" color="inherit" sx={{ fontSize: '1rem', fontFamily: 'Dubai', fontWeight: 'none', textDecoration: 'none' }}>
              Email: support@techtrader.com<br />
              Phone: +123 456 7890
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ fontFamily: 'Dubai' }}>Legal</Typography>
            <Link href="#" color="inherit" sx={{ fontFamily: 'Dubai', textDecoration: 'none' }}>Terms of Use</Link><br />
            <Link href="#" color="inherit" sx={{ fontFamily: 'Dubai', textDecoration: 'none' }}>Privacy Policy</Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
