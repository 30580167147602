import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/global.css';
import { ThemeProviderWrapper } from './components/ThemeContext';  // Import the ThemeProviderWrapper

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProviderWrapper>  {/* Wrap your app here */}
      <App />
    </ThemeProviderWrapper>
  </React.StrictMode>
);

reportWebVitals();
