import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { componentsApi } from './ComponentsApi'
import { buildsApi } from './BuildsApi'
import { offersApi } from './OffersApi'
import { usersApi } from './UsersApi'

export const store = configureStore({
    reducer: {
        [componentsApi.reducerPath]: componentsApi.reducer,
        [buildsApi.reducerPath]: buildsApi.reducer,
        [offersApi.reducerPath]: offersApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(componentsApi.middleware)
            .concat(offersApi.middleware)
            .concat(buildsApi.middleware)
            .concat(usersApi.middleware)
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// see import { setupListeners } from '@reduxjs/toolkit/query'
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch