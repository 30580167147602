import React, { useState } from 'react';
import { Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Container, Paper, SelectChangeEvent, Tooltip, IconButton, Grid } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Checkbox } from '@mui/material';

const CustomButton = styled(Button)({
  fontSize: '14px',
  display: 'inline-block',
  outline: '0',
  border: '0',
  cursor: 'pointer',
  willChange: 'box-shadow, transform',
  background: 'radial-gradient(100% 100% at 100% 10%, #1976d2 0%, #1976d2 100%)',
  boxShadow: '0px 0.01em 0.01em rgb(45 35 66 / 40%), 0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%)',
  padding: '0 2em',
  borderRadius: '0.3em',
  color: '#fff',
  height: '2.6em',
  textShadow: '0 1px 0 rgb(0 0 0 / 40%)',
  transition: 'box-shadow 0.15s ease, transform 0.15s ease',
  '&:hover': {
    boxShadow: '0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #3c4fe0',
    transform: 'translateY(-0.1em)',
  },
  '&:active': {
    boxShadow: 'inset 0px 0.1em 0.6em #3c4fe0',
    transform: 'translateY(0em)',
  },
});

const HiddenRadioInput = styled('input')({
  position: 'absolute',
  opacity: 0,
  width: 0,
  height: 0,
});

const StyledRadioTile = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80px',
  minHeight: '80px',
  borderRadius: '0.5rem',
  border: `2px solid ${theme.palette.mode === 'dark' ? '#777' : '#b5bfd9'}`,
  backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#fff',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 5px 10px rgba(0, 0, 0, 0.5)'
    : '0 5px 10px rgba(0, 0, 0, 0.1)',
  transition: '0.15s ease',
  cursor: 'pointer',
  position: 'relative',
  margin: '0 10px',
  textAlign: 'center',
  '&:before': {
    content: '""',
    position: 'absolute',
    display: 'block',
    width: '0.75rem',
    height: '0.75rem',
    border: `2px solid ${theme.palette.mode === 'dark' ? '#777' : '#b5bfd9'}`,
    backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#fff',
    borderRadius: '50%',
    top: '0.25rem',
    left: '0.25rem',
    opacity: 0,
    transform: 'scale(0)',
    transition: '0.25s ease',
  },
  '&:hover': {
    borderColor: '#2260ff',
  },
  '&:hover:before': {
    transform: 'scale(1)',
    opacity: 1,
  },
  '&.checked': {
    borderColor: '#2260ff',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 5px 10px rgba(0, 0, 0, 0.5)'
      : '0 5px 10px rgba(0, 0, 0, 0.1)',
    color: '#2260ff',
  },
  '&.checked:before': {
    transform: 'scale(1)',
    opacity: 1,
    backgroundColor: '#2260ff',
    borderColor: '#2260ff',
  },
  '& .radio-icon': {
    width: '100%',
    fontSize: '1.5rem',
    fill: theme.palette.mode === 'dark' ? '#ddd' : '#494949',
  },
  '& .radio-label': {
    color: theme.palette.mode === 'dark' ? '#ddd' : '#707070',
    transition: '0.375s ease',
    textAlign: 'center',
    fontSize: '13px',
  },
  '&.checked .radio-label': {
    color: '#2260ff',
  },
}));

type Manufacturer = { name: string; models: string[] };

const manufacturers: { [key: string]: Manufacturer[] } = {
  GPU: [
    { name: 'Nvidia', models: ['RTX 3080', 'RTX 3070', 'RTX 3060'] },
    { name: 'AMD', models: ['Radeon RX 6800', 'Radeon RX 6700', 'Radeon RX 6600'] }
  ],
  CPU: [
    { name: 'Intel', models: ['Core i9', 'Core i7', 'Core i5'] },
    { name: 'AMD', models: ['Ryzen 9', 'Ryzen 7', 'Ryzen 5'] }
  ],
  // Add more categories and manufacturers as needed
};

const categories = [
  { value: 'CPU', label: 'CPU' },
  { value: 'GPU', label: 'GPU' },
  { value: 'Motherboard', label: 'Motherboard' },
  { value: 'Case', label: 'Case' },
  { value: 'Others', label: 'Others' }
];

function CreateListingForm2() {
  const [formData, setFormData] = useState({
    title: '',
    model: '',
    manufacturer: '',
    price: '',
    description: '',
    listingType: '', // New state to handle the type of item
    specifications: '', // New state for whole PC specifications
    components: '' // New state for whole PC components
  });
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [condition, setCondition] = useState<string>('');
  const [manufacturerList, setManufacturerList] = useState<Manufacturer[]>([]);
  const [modelList, setModelList] = useState<string[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      listingType: checked ? value : '',  // Assign value if checked, otherwise clear it
    }));
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, listingType: e.target.value });
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory);
    setManufacturerList(manufacturers[selectedCategory] || []);
    setFormData({ ...formData, manufacturer: '', model: '' });
    setModelList([]);
  };

  const handleManufacturerChange = (event: SelectChangeEvent<string>) => {
    const selectedManufacturer = event.target.value;
    setFormData({ ...formData, manufacturer: selectedManufacturer, model: '' });
    const selectedManufacturerData = manufacturers[category].find(m => m.name === selectedManufacturer);
    setModelList(selectedManufacturerData ? selectedManufacturerData.models : []);
  };

  const handleModelChange = (event: SelectChangeEvent<string>) => {
    setFormData({ ...formData, model: event.target.value });
  };

  const handleConditionChange = (event: SelectChangeEvent<string>) => {
    setCondition(event.target.value);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Handle image upload
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Validate inputs and handle form submission
    console.log(formData);
  };

  return (
<Container component="main" maxWidth="lg"> {/* Increased maxWidth */}
      <Paper elevation={6} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold', textAlign: 'left' }}>Create New Listing</Typography> {/* Left-align the heading */}
        <Box component="form" onSubmit={handleSubmit} sx={{ '& .MuiTextField-root': { mb: 2 } }}>
          <Grid container spacing={2} alignItems="center" sx={{ mb: 4 }}>
            <Grid item xs={3}>
              <Typography sx={{ fontWeight: 'bold' }}>What do you want to sell?</Typography>
            </Grid>
            <Grid item xs={9}>
              <Box className="radio-inputs" sx={{ ml: -1, display: 'flex', justifyContent: 'flex-start' }}>
                <label>
                  <Checkbox sx={{ display: 'none' }}
                    name="listingType"
                    value="part"
                    checked={formData.listingType === 'part'}
                    onChange={handleCheckboxChange}
                  />
                  <StyledRadioTile className={formData.listingType === 'part' ? 'checked' : ''}>
                    <div className="radio-icon">💻</div>
                    <div className="radio-label">PC Part</div>
                  </StyledRadioTile>
                </label>
                <label>
                  <Checkbox sx={{ display: 'none' }}
                    name="listingType"
                    value="whole"
                    checked={formData.listingType === 'whole'}
                    onChange={handleCheckboxChange}
                    className="radio-input"
                  />
                  <StyledRadioTile className={formData.listingType === 'whole' ? 'checked' : ''}>
                    <div className="radio-icon">🖥️</div>
                    <div className="radio-label">Whole PC</div>
                  </StyledRadioTile>
                </label>
              </Box>
            </Grid>
          </Grid>

         {formData.listingType === 'part' && (
            <Grid container spacing={2} alignItems="center" sx={{ mb: 4 }}>
              <Grid item xs={3}>
                <Typography sx={{ fontWeight: 'bold' }}>Category</Typography>
              </Grid>
              <Grid item xs={9}>
                <Box className="radio-inputs" sx={{ ml: -1, display: 'flex', justifyContent: 'flex-start' }}>
                  {categories.map((category) => (
                    <label key={category.value}>
                      <HiddenRadioInput
                        type="radio"
                        name="category"
                        value={category.value}
                        checked={selectedCategory === category.value}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                      />
                      <StyledRadioTile className={selectedCategory === category.value ? 'checked' : ''}>
                        {category.value === 'GPU' ? (
                          <>
                            <img
                              src="/GPU.svg"
                              alt="GPU Icon"
                              width="60"  // Adjust the size for GPU
                              height="auto"
                              style={{marginTop:'13px'}}
                            />
                            <div className="radio-label" style={{ marginTop: '12px' }}>{category.label}</div>
                          </>
                        ) : category.value === 'CPU' ? (
                          <>
                            <img
                              src="/CPU.svg"
                              alt="CPU Icon"
                              width="40"  // Adjust the size for CPU
                              height="auto"
                              style={{marginTop:'5px'}}
                            />
                            <div className="radio-label" style={{ marginTop: '8px' }}>{category.label}</div>
                          </>
                        ) : (
                          <div className="radio-label">{category.label}</div>
                        )}
                      </StyledRadioTile>
                    </label>
                  ))}
                </Box>
              </Grid>
            </Grid>
          )} 

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <Typography sx={{ textAlign: 'left', transform: 'translateY(-50%)' }}>Title</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Enter the name of the product you are listing.">
                <IconButton>
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {formData.listingType === 'part' && (
            <>
              <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                <Grid item xs={3}>
                  <Typography>Category</Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth sx={{ mr: 1 }}>
                    <InputLabel>Category</InputLabel>
                    <Select value={category} label="Category" onChange={handleCategoryChange} required>
                      <MenuItem value="GPU">GPU</MenuItem>
                      <MenuItem value="CPU">CPU</MenuItem>
                      <MenuItem value="Motherboard">Motherboard</MenuItem>
                      <MenuItem value="Case">Case</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title="Select the category that best fits the product.">
                    <IconButton>
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

              {manufacturerList.length > 0 && (
                <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                  <Grid item xs={3}>
                    <Typography>Manufacturer</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth sx={{ mr: 1 }}>
                      <InputLabel>Manufacturer</InputLabel>
                      <Select value={formData.manufacturer} label="Manufacturer" onChange={handleManufacturerChange} required>
                        {manufacturerList.map((manufacturer) => (
                          <MenuItem key={manufacturer.name} value={manufacturer.name}>
                            {manufacturer.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title="Select the manufacturer of the product.">
                      <IconButton>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}

              {modelList.length > 0 && (
                <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                  <Grid item xs={3}>
                    <Typography>Model</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth sx={{ mr: 1 }}>
                      <InputLabel>Model</InputLabel>
                      <Select value={formData.model} label="Model" onChange={handleModelChange} required>
                        {modelList.map((model) => (
                          <MenuItem key={model} value={model}>
                            {model}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title="Select the model of the product.">
                      <IconButton>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {formData.listingType === 'whole' && (
            <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
              <Grid item xs={3}>
                <Typography>Specifications</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  label="Specifications"
                  name="specifications"
                  multiline
                  rows={4}
                  value={formData.specifications}
                  onChange={handleChange}
                  required
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={1}>
                <Tooltip title="Provide the specifications for the whole PC.">
                  <IconButton>
                    <HelpOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Typography>Condition</Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth sx={{ mr: 1 }}>
                <InputLabel>Condition</InputLabel>
                <Select value={condition} label="Condition" onChange={handleConditionChange} required>
                  <MenuItem value="New">New</MenuItem>
                  <MenuItem value="Used">Used</MenuItem>
                  <MenuItem value="Refurbished">Refurbished</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Select the condition of the product.">
                <IconButton>
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Typography>Price</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Price"
                name="price"
                type="number"
                value={formData.price}
                onChange={handleChange}
                required
                InputProps={{ inputProps: { min: 0 } }} // Ensures price cannot be negative
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Typography>Description</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                multiline
                rows={4}
                value={formData.description}
                onChange={handleChange}
                required
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Typography>Upload Image</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Upload Image"
                type="file"
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: "image/*" }} // Accepts image files only
                onChange={handleImageChange}
              />
            </Grid>
          </Grid>

          <CustomButton type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
            Create Listing
          </CustomButton>
        </Box>
      </Paper>
    </Container>
  );
}

export default CreateListingForm2;
