import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControlLabel, Checkbox, Collapse } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ComponentFilter } from '../app/ComponentsApi';

// Defining the options for the checkboxes
const categoryOptions = [
    { label: 'GPU', value: 'GPU' },
    { label: 'CPU', value: 'CPU' },
    { label: 'Motherboard', value: 'Motherboard' },
    { label: 'Case', value: 'Case' },
    { label: 'Others', value: 'Others' },
];

const conditionOptions = [
    { label: 'New', value: 'New' },
    { label: 'Used', value: 'Used' },
    { label: 'Refurbished', value: 'Refurbished' },
];

// Manufacturer options mapped to categories
const manufacturers = {
    GPU: [{ name: 'NVIDIA' }, { name: 'AMD' }],
    CPU: [{ name: 'Intel' }, { name: 'AMD' }],
    Motherboard: [{ name: 'ASUS' }, { name: 'MSI' }],
    Case: [{ name: 'NZXT' }, { name: 'Corsair' }],
    Others: [{ name: 'Generic' }]
};

// Release years
const yearOptions = [
    { label: '2020', value: '2020' },
    { label: '2019', value: '2019' },
    { label: '2018', value: '2018' },
];

const FiltersSidebar: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const urlSearchParams = new URLSearchParams(location.search);
    const category = urlSearchParams.get('category') || '';
    const manufacturer = urlSearchParams.get('manufacturer') || '';
    const year = urlSearchParams.get('year') || '';
    const condition = urlSearchParams.get('condition') || '';

    const [selectedCategory, setSelectedCategory] = useState<string[]>(category ? [category] : []);
    const [selectedManufacturer, setSelectedManufacturer] = useState<string[]>(manufacturer ? [manufacturer] : []);
    const [selectedYear, setSelectedYear] = useState<string[]>(year ? [year] : []);
    const [selectedCondition, setSelectedCondition] = useState<string[]>(condition ? [condition] : []);
    const [manufacturerList, setManufacturerList] = useState<{ name: string }[]>([]);

    useEffect(() => {
        if (selectedCategory.length > 0) {
            const newManufacturerList = selectedCategory.flatMap(cat => manufacturers[cat as keyof typeof manufacturers] || []);
            setManufacturerList(newManufacturerList);
        } else {
            setManufacturerList([]);
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (manufacturer) {
            setSelectedManufacturer([manufacturer]);
        }
        if (category) {
            setSelectedCategory([category]);
        }
        if (year) {
            setSelectedYear([year]);
        }
        if (condition) {
            setSelectedCondition([condition]);
        }
    }, [manufacturer, category, year, condition]);

    const updateFilters = (updatedCategories: string[], updatedManufacturers: string[], updatedYears: string[], updatedConditions: string[]) => {
        setSelectedCategory(updatedCategories);
        setSelectedManufacturer(updatedManufacturers);
        setSelectedYear(updatedYears);
        setSelectedCondition(updatedConditions);

        let url = '/components';
        let filter = new ComponentFilter(
            updatedCategories.join(','),
            updatedManufacturers.join(',')
        );
        url += '?' + filter.encodeAsURIQuery();
        navigate(url);
    };

    const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const updatedCategories = event.target.checked
            ? [...selectedCategory, value]
            : selectedCategory.filter(cat => cat !== value);
        updateFilters(updatedCategories, selectedManufacturer, selectedYear, selectedCondition);
    };

    const handleManufacturerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const updatedManufacturers = event.target.checked
            ? [...selectedManufacturer, value]
            : selectedManufacturer.filter(man => man !== value);
        updateFilters(selectedCategory, updatedManufacturers, selectedYear, selectedCondition);
    };

    const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const updatedYears = event.target.checked
            ? [...selectedYear, value]
            : selectedYear.filter(yr => yr !== value);
        updateFilters(selectedCategory, selectedManufacturer, updatedYears, selectedCondition);
    };

    const handleConditionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const updatedConditions = event.target.checked
            ? [...selectedCondition, value]
            : selectedCondition.filter(cond => cond !== value);
        updateFilters(selectedCategory, selectedManufacturer, selectedYear, updatedConditions);
    };

    return (
        <Box sx={{ mt: 7.5, ml: 4, width:'50%',borderRadius: '20px', p: 2, border: '1px solid rgba(128, 128, 128, 0.3)' }}>
            <Typography color="primary" sx={{ fontWeight: 'bold' }} variant="h6">Filters</Typography>

            <Typography color="primary" sx={{ fontWeight: 'bold' }} variant="subtitle1">Category</Typography>
            {categoryOptions.map(option => (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedCategory.includes(option.value)}
                            onChange={handleCategoryChange}
                            value={option.value}
                        />
                    }
                    label={option.label}
                    key={option.value}
                    sx={{ display: 'block', ml: 2, color: 'grey' }}
                />
            ))}

            <Collapse in={selectedCategory.length > 0}>
                <Typography color="primary" sx={{ fontWeight: 'bold' }} variant="subtitle1">Manufacturer</Typography>
                {manufacturerList.map(option => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedManufacturer.includes(option.name)}
                                onChange={handleManufacturerChange}
                                value={option.name}
                            />
                        }
                        label={option.name}
                        key={option.name}
                        sx={{ display: 'block', ml: 2, color: 'grey' }}
                    />
                ))}
            </Collapse>

            <Typography color="primary" sx={{ fontWeight: 'bold' }} variant="subtitle1">Release Year</Typography>
            {yearOptions.map(option => (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedYear.includes(option.value)}
                            onChange={handleYearChange}
                            value={option.value}
                        />
                    }
                    label={option.label}
                    key={option.value}
                    sx={{ display: 'block', ml: 2, color: 'grey' }}
                />
            ))}

            <Typography color="primary" sx={{ fontWeight: 'bold' }} variant="subtitle1">Condition</Typography>
            {conditionOptions.map(option => (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedCondition.includes(option.value)}
                            onChange={handleConditionChange}
                            value={option.value}
                        />
                    }
                    label={option.label}
                    key={option.value}
                    sx={{ display: 'block', ml: 2, color: 'grey' }}
                />
            ))}
        </Box>
    );
};

export default FiltersSidebar;
