import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import type { Location } from "@remix-run/router";

import { ComponentDataObject, ComponentsService, OpenAPI } from '../openapi';

let fetchQuery: any = fetchBaseQuery({ baseUrl: OpenAPI.BASE })

export const CreateComponentFilter = (location: Location<any>) => {
    const urlSearchParams = new URLSearchParams(location.search);

    const type = urlSearchParams.get('type') || undefined;
    const manufacturer = urlSearchParams.get('manufacturer') || undefined;

    return new ComponentFilter(type, manufacturer);
}

export class ComponentFilter {
    constructor(
        public type?: string,
        public manufacturer?: string,
        public minPrice?: string,
        public maxPrice?: string
    ) { }

    encodeAsURIQuery(): string {
        const filterParts: string[] = [];

        if (this.type) {
            filterParts.push(`type=${encodeURIComponent(this.type)}`);
        }

        if (this.manufacturer) {
            filterParts.push(`manufacturer=${encodeURIComponent(this.manufacturer)}`);
        }
        if (this.minPrice) filterParts.push(`minPrice=${encodeURIComponent(this.minPrice)}`);
        if (this.maxPrice) filterParts.push(`maxPrice=${encodeURIComponent(this.maxPrice)}`);


        return filterParts.join('&');
    }

    asApiComponentFilter(): ApiComponentFilter {
        return { type: this.type || "", manufacturer: this.manufacturer || "" }
    }
}

export interface ApiComponentFilter
{
    type: string,
    manufacturer: string,
}

export const componentsApi = createApi({
    reducerPath: 'componentsApi',
    baseQuery: fetchQuery,
    tagTypes: ['Components'],
    endpoints: (builder) => ({
        getComponents: builder.query<Array<ComponentDataObject>, ApiComponentFilter | void>({
            queryFn: async (filter: ApiComponentFilter | void) => {
                const data = await ComponentsService.getApiComponents(filter?.type, filter?.manufacturer);

                return { data }
            }
        }),
        getComponentsByIds: builder.mutation<Array<ComponentDataObject>, Array<string>>({
            queryFn: async (componentIds) => {

                const promises = componentIds.map(id => ComponentsService.getApiComponents1(id));

                const results = await Promise.all(promises);

                return { data:results }
            },
        }),
        getComponent: builder.query<ComponentDataObject, string>({
            queryFn: async (id: string) => {
                const data = await ComponentsService.getApiComponents1(id);

                return { data }
            }
        }),
    }),
})


export const { useGetComponentsQuery, useGetComponentQuery, useGetComponentsByIdsMutation } = componentsApi