import React from 'react';
import { Container, Typography, Box, Avatar, Grid, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const testimonials = [
    {
        name: 'Johna Doe',
        avatar: 'https://i.pravatar.cc/150?img=1',
        review: 'This platform is amazing! I was able to buy all the components I needed for my new PC build at great prices.',
    },
    {
        name: 'Jane Smith',
        avatar: 'https://i.pravatar.cc/150?img=2',
        review: 'Selling my old GPU was so easy and fast. Highly recommend this site to everyone!',
    },
    {
        name: 'Sam Wilson',
        avatar: 'https://i.pravatar.cc/150?img=3',
        review: 'Great experience! The compatibility check feature saved me a lot of headaches.',
    },
];

const SocialProof: React.FC = () => {
    const theme = useTheme();

    const statistics = [
        { label: 'Users', value: '10,000+', icon: <EmojiEventsIcon fontSize="large" style={{ color: theme.palette.primary.main }} /> },
        { label: 'Components Sold', value: '5,000+', icon: <StarIcon fontSize="large" style={{ color: theme.palette.primary.main }} /> },
        { label: 'Positive Reviews', value: '2,500+', icon: <ThumbUpIcon fontSize="large" style={{ color: theme.palette.primary.main }} /> },
    ];

    return (
        <Container>
            <Box my={4} textAlign="center">
                <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                    What Our Users Say
                </Typography>
                <Grid container spacing={4}>
                    {testimonials.map((testimonial, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Paper
                                elevation={6}
                                sx={{
                                    padding: 4,
                                    textAlign: 'center',
                                    color: 'text.secondary',
                                    borderRadius: 3,
                                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                                    background: 'linear-gradient(to right, #f8f9fa, #e9ecef)',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    '&:hover': {
                                        boxShadow: '0rem 6px 13px rgba(0, 0, 0, 0.1), 0rem 24px 24px rgba(0, 0, 0, 0.09), 0rem 55px 33px rgba(0, 0, 0, 0.05), 0rem 97px 39px rgba(0, 0, 0, 0.01), 0rem 152px 43px rgba(0, 0, 0, 0)',
                                        borderColor: '#0a3cff',
                                        transform: 'scale(1.05)',
                                    }
                                }}
                                className="card"
                            >
                                <div className="content">
                                    <Box display="flex" alignItems="center" justifyContent="center" mb={2} sx={{ position: 'relative' }}>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                width: 70,
                                                height: 70,
                                                borderRadius: '50%',
                                                background: 'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(230,230,230,1) 100%)',
                                                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 1,
                                            }}
                                        >
                                            <Avatar src={testimonial.avatar} alt={testimonial.name} sx={{ width: 56, height: 56 }} />
                                        </Box>
                                    </Box>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 5 }} className="heading">
                                        {testimonial.name}
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontStyle: 'italic', color: theme.palette.text.primary }} className="para">
                                        "{testimonial.review}"
                                    </Typography>
                                </div>
                                <div className="cardBefore" />
                                <div className="cardAfter" />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box my={6} textAlign="center">
                <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                    Our Achievements
                </Typography>
                <Grid container spacing={4}>
                    {statistics.map((stat, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Paper
                                elevation={6}
                                sx={{
                                    padding: 4,
                                    textAlign: 'center',
                                    borderRadius: 3,
                                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                                    background: 'linear-gradient(to right, #f8f9fa, #e9ecef)',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    '&:hover': {
                                        boxShadow: '0rem 6px 13px rgba(0, 0, 0, 0.1), 0rem 24px 24px rgba(0, 0, 0, 0.09), 0rem 55px 33px rgba(0, 0, 0, 0.05), 0rem 97px 39px rgba(0, 0, 0, 0.01), 0rem 152px 43px rgba(0, 0, 0, 0)',
                                        borderColor: '#0a3cff',
                                        transform: 'scale(1.05)',
                                    }
                                }}
                                className="card"
                            >
                                <Box display="flex" flexDirection="column" alignItems="center" className="content">
                                    {stat.icon}
                                    <Typography variant="h5" component="h3" sx={{ fontWeight: 'bold', mt: 2 }} className="heading">
                                        {stat.value}
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }} className="para">
                                        {stat.label}
                                    </Typography>
                                </Box>
                                <div className="cardBefore" />
                                <div className="cardAfter" />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
};

export default SocialProof;
