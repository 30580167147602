import React, { useState } from 'react';
import { Box, Container, CssBaseline, Grid, Paper, Typography, Button, List, ListItem, ListItemText, Switch, FormControlLabel, AppBar, Toolbar, IconButton, Badge, TextField, ListItemIcon } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListIcon from '@mui/icons-material/List';
import MessageIcon from '@mui/icons-material/Message';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import StarIcon from '@mui/icons-material/Star';

const theme = createTheme();

const SellerDashboard: React.FC = () => {
    const [selectedSection, setSelectedSection] = useState('Overview');
    const theme = useTheme(); 

    const reviewsReceived = [
        { user: 'Alice', text: 'Great seller!', rating: 5 },
        { user: 'Bob', text: 'Fast shipping and good communication.', rating: 4 }
    ];

    const reviewsLeft = [
        { user: 'Charlie', text: 'Item as described. Thank you!', rating: 5 },
        { user: 'Dave', text: 'Quick response and fast payment.', rating: 4 }
    ];

    const renderSection = () => {
        switch (selectedSection) {
            case 'Overview':
                return (
                    <Box>
                        <Typography variant="h4" gutterBottom sx={{ color: theme.palette.text.primary }}>
                            Dashboard Overview
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={4}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                    <Typography variant="h6">Total Sales</Typography>
                                    <Typography variant="h4" color="primary.main">$5,000</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                    <Typography variant="h6">Total Listings</Typography>
                                    <Typography variant="h4" color="primary.main">150</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                    <Typography variant="h6">Recent Activity</Typography>
                                    <Typography>Last Sale: 2 hours ago</Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                );
            case 'Listings':
                return (
                    <Box>
                        <Typography variant="h4" gutterBottom>
                            Listings Management
                        </Typography>
                        <Button variant="contained" color="primary" sx={{ mb: 3 }}>
                            Add New Listing
                        </Button>
                        <Grid container spacing={3}>
                            {[
                                { title: 'Intel Core i9', price: '$450', quantity: 10 },
                                { title: 'NVIDIA RTX 3080', price: '$700', quantity: 5 },
                                { title: 'ASUS ROG Strix', price: '$150', quantity: 8 },
                            ].map((listing, index) => (
                                <Grid item xs={12} md={6} lg={4} key={index}>
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.05)' } }}>
                                        <Typography variant="h6">{listing.title}</Typography>
                                        <Typography variant="body1" color="primary.main">{listing.price}</Typography>
                                        <Typography variant="body2">Quantity: {listing.quantity}</Typography>
                                        <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                                            Edit Listing
                                        </Button>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                );
            case 'Messages':
                return (
                    <Box>
                        <Typography variant="h4" gutterBottom>
                            Messages
                        </Typography>
                        <Paper sx={{ p: 2 }}>
                            <List>
                                {[
                                    { from: 'Buyer1', text: 'I am interested in your Intel Core i9 listing. Is it still available?' },
                                    { from: 'Buyer2', text: 'Can you provide more details about the NVIDIA RTX 3080?' },
                                ].map((message, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={`Message from ${message.from}`}
                                            secondary={message.text}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Box>
                );
                case 'Profile':
                    return (
                        <Box>
                            <Typography variant="h4" gutterBottom sx={{ color: theme.palette.text.primary }}>
                                Profile Management
                            </Typography>
                            <Paper sx={{ p: 2, bgcolor: theme.palette.background.paper }}>
                                <Box component="form" noValidate autoComplete="off">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <TextField fullWidth label="Name" variant="outlined" sx={{ color: theme.palette.text.primary }} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField fullWidth label="Email" variant="outlined" sx={{ color: theme.palette.text.primary }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField fullWidth label="Address" variant="outlined" sx={{ color: theme.palette.text.primary }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{ mt: 3, color: theme.palette.text.primary }}>
                                                Reset Password
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                label="New Password"
                                                type="password"
                                                variant="outlined"
                                                sx={{ mb: 2 }}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Confirm New Password"
                                                type="password"
                                                variant="outlined"
                                                sx={{ mb: 2 }}
                                            />
                                            <Button variant="contained" color="primary">
                                                Save Changes
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                    );                
            case 'Analytics':
                return (
                    <Box>
                        <Typography variant="h4" gutterBottom>
                            Sales Analytics
                        </Typography>
                        <Paper sx={{ p: 2 }}>
                            {/* Placeholder for sales analytics */}
                            <Typography>Sales data and charts will be displayed here.</Typography>
                        </Paper>
                    </Box>
                );
            case 'Orders':
                return (
                    <Box>
                        <Typography variant="h4" gutterBottom>
                            Order Management
                        </Typography>
                        <Paper sx={{ p: 2 }}>
                            <List>
                                {[
                                    { orderId: '#12345', details: 'Intel Core i9 - $450 - Quantity: 1 - Status: Shipped', status: 'shipped' },
                                    { orderId: '#12346', details: 'NVIDIA RTX 3080 - $700 - Quantity: 2 - Status: Processing', status: 'processing' },
                                ].map((order, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={`Order ${order.orderId}`}
                                            secondary={order.details}
                                        />
                                        {order.status === 'shipped' && <CheckCircleIcon color="success" />}
                                        {order.status === 'processing' && <HourglassEmptyIcon color="action" />}
                                        <Button variant="contained" color="primary">View Details</Button>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Box>
                );
            case 'Settings':
                return (
                    <Box>
                        <Typography variant="h4" gutterBottom>
                            Settings
                        </Typography>
                        <Paper sx={{ p: 2 }}>
                            <FormControlLabel
                                control={<Switch color="primary" />}
                                label="Enable Notifications"
                            />
                            <FormControlLabel
                                control={<Switch color="primary" />}
                                label="Enable Dark Mode"
                            />
                        </Paper>
                    </Box>
                );
            case 'Reviews':
                return (
                    <Box>
                        <Typography variant="h4" gutterBottom>
                            Reviews
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Paper sx={{ p: 2 }}>
                                    <Typography variant="h6">Reviews Received</Typography>
                                    {reviewsReceived.map((review, index) => (
                                        <Box key={index} sx={{ my: 2 }}>
                                            <Typography variant="body1">{review.text} - {review.user}</Typography>
                                            <Box>
                                                {[...Array(review.rating)].map((_, i) => (
                                                    <StarIcon key={i} color="primary" />
                                                ))}
                                                {[...Array(5 - review.rating)].map((_, i) => (
                                                    <StarIcon key={i} color="disabled" />
                                                ))}
                                            </Box>
                                        </Box>
                                    ))}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Paper sx={{ p: 2 }}>
                                    <Typography variant="h6">Reviews Left</Typography>
                                    {reviewsLeft.map((review, index) => (
                                        <Box key={index} sx={{ my: 2 }}>
                                            <Typography variant="body1">{review.text} - {review.user}</Typography>
                                            <Box>
                                                {[...Array(review.rating)].map((_, i) => (
                                                    <StarIcon key={i} color="primary" />
                                                ))}
                                                {[...Array(5 - review.rating)].map((_, i) => (
                                                    <StarIcon key={i} color="disabled" />
                                                ))}
                                            </Box>
                                        </Box>
                                    ))}
                                </Paper>
                            </Grid>
                        </Grid>
                        <Paper sx={{ p: 2, mt: 3 }}>
                            <Typography variant="h6">Leave a Review</Typography>
                            <TextField
                                label="Your Review"
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            <Box>
                                {[1, 2, 3, 4, 5].map((star) => (
                                    <Button key={star}>
                                        <StarIcon />
                                    </Button>
                                ))}
                            </Box>
                            <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                                Submit Review
                            </Button>
                        </Paper>
                    </Box>
                );
            default:
                return <Typography variant="h6">Select a section from the sidebar</Typography>;
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container maxWidth="lg">
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                    <Grid container spacing={2} sx={{ flexGrow: 1, mt: 2 }}>
                        <Grid item xs={12} md={3}>
                            <Paper sx={{ p: 2 }}>
                                <List>
                                    <ListItem button onClick={() => setSelectedSection('Overview')}>
                                        <ListItemIcon><DashboardIcon /></ListItemIcon>
                                        <ListItemText primary="Dashboard" />
                                    </ListItem>
                                    <ListItem button onClick={() => setSelectedSection('Listings')}>
                                        <ListItemIcon><ListIcon /></ListItemIcon>
                                        <ListItemText primary="Listings" />
                                    </ListItem>
                                    <ListItem button onClick={() => setSelectedSection('Messages')}>
                                        <ListItemIcon><MessageIcon /></ListItemIcon>
                                        <ListItemText primary="Messages" />
                                    </ListItem>
                                    <ListItem button onClick={() => setSelectedSection('Profile')}>
                                        <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                                        <ListItemText primary="Profile" />
                                    </ListItem>
                                    <ListItem button onClick={() => setSelectedSection('Analytics')}>
                                        <ListItemIcon><AssessmentIcon /></ListItemIcon>
                                        <ListItemText primary="Sales Analytics" />
                                    </ListItem>
                                    <ListItem button onClick={() => setSelectedSection('Orders')}>
                                        <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
                                        <ListItemText primary="Orders" />
                                    </ListItem>
                                    <ListItem button onClick={() => setSelectedSection('Settings')}>
                                        <ListItemIcon><SettingsIcon /></ListItemIcon>
                                        <ListItemText primary="Settings" />
                                    </ListItem>
                                    <ListItem button onClick={() => setSelectedSection('Reviews')}>
                                        <ListItemIcon><StarIcon /></ListItemIcon>
                                        <ListItemText primary="Reviews" />
                                    </ListItem>
                                </List>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            {renderSection()}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default SellerDashboard;
