import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Grid, Typography, TextField, Card, CardActionArea, CardContent, CardMedia, Button, Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import ComputerIcon from '@mui/icons-material/Computer';
import MemoryIcon from '@mui/icons-material/Memory';
import StorageIcon from '@mui/icons-material/Storage';
import PowerIcon from '@mui/icons-material/Power';
import CaseIcon from '@mui/icons-material/Cases';
import CoolingIcon from '@mui/icons-material/AcUnit';
import PeripheralIcon from '@mui/icons-material/DevicesOther';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import SaveIcon from '@mui/icons-material/Save';
import { useAddNewBuildMutation, useDeleteBuildMutation, useGetBuildsQuery, useGetComponentsForCurrentBuildQuery, useGetCurrentBuildQuery, useRemoveComponentFromCurrentBuildMutation, useSetCurrentBuildMutation } from '../app/BuildsApi';
import { BuildDataObject, ComponentDataObject } from '../openapi';
import { useAddComponentsFromBuildMutation } from '../app/OffersApi';

const componentSuggestions = [
    { name: 'CPU', type: 'cpu', icon: <ComputerIcon /> },
    { name: 'GPU', type: 'gpu', icon: <ComputerIcon /> },
    { name: 'Motherboards', type: 'motherboard', icon: <MemoryIcon /> },
    { name: 'RAM', type: 'ram', icon: <MemoryIcon /> },
    { name: 'Storage', type: 'storage', icon: <StorageIcon /> },
    { name: 'Power Supplies', type: 'power-supply', icon: <PowerIcon /> },
    { name: 'Cases', type: 'case', icon: <CaseIcon /> },
    { name: 'Cooling', type: 'cooling', icon: <CoolingIcon /> },
    { name: 'Peripherals', type: 'peripherals', icon: <PeripheralIcon /> }
];

// TODO fix image stretching
const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    width: '100%',       // Make the image stretch horizontally
    maxWidth: 'none',    // Override the maxWidth to allow stretching
    height: 'auto',      // Maintain aspect ratio
    maxHeight: '100%',   // Ensure the image does not exceed the container's height
});

const ComponentSuggestion: React.FC<{
    componentSuggestion: any,
    buildComponents: ComponentDataObject[],
    removeComponent: (id: string) => void
}> = ({ componentSuggestion, buildComponents, removeComponent }) => {

    const navigate = useNavigate();

    let componentName = componentSuggestion.name;
    let componentIcon = componentSuggestion.icon;
    let componentImage = undefined;
    let componentLink = "/components?type=" + componentSuggestion.type;

    let component: ComponentDataObject | undefined;

    component = buildComponents?.find(c => c.type.name?.toLowerCase() === componentSuggestion.type.toLowerCase());

    if (component) {
        componentName = component.identifier;
        componentImage = "card_preview_placeholder.png";
    }

    return (
        <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 345, boxShadow: 3, '&:hover': { boxShadow: 6 } }}>
                <CardActionArea onClick={() => navigate(componentLink)}>
                    <CardMedia
                        component="div"
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 140, bgcolor: 'primary.light' }}
                    >
                        {componentImage ?
                            <Img alt="CARD PREVIEW IMAGE" src="/card_preview_placeholder.png" />
                            : componentIcon
                        }
                    </CardMedia>
                    <CardContent>
                        <Typography variant="h6" component="div">
                            {componentName}
                            {/* <Button size="small" color="primary" fullWidth href={componentLink}>
                                Change {componentName}
                            </Button> */}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                {
                    component ? (
                        <Button size="small" color="primary" fullWidth onClick={() => removeComponent(component!.id!)}>
                            Remove {componentName}
                        </Button>
                    ) :
                        (
                            <Button size="small" color="primary" fullWidth onClick={() => navigate(componentLink)}>
                                Add {componentName}
                            </Button>
                        )
                }
            </Card>
        </Grid>
    )
}

const BuildPage = () => {
    const navigate = useNavigate();

    const { data: build } = useGetCurrentBuildQuery();

    const { data: buildComponents } = useGetComponentsForCurrentBuildQuery();

    const { data: builds } = useGetBuildsQuery();
    const [selectedBuildId, setSelectedBuildId] = useState<string>("");

    const [removeComponent] = useRemoveComponentFromCurrentBuildMutation();
    const [addBuild] = useAddNewBuildMutation();

    const [addComponentsFromBuildToCard] = useAddComponentsFromBuildMutation();
    const [deleteBuild] = useDeleteBuildMutation();

    const [setCurrentBuild] = useSetCurrentBuildMutation();

    const handleMoveBuildToCard = (buildId: string) => {
        addComponentsFromBuildToCard(buildId)
        deleteBuild()
        navigate("/cart")
    }

    const [newBuildName, setNewBuildName] = useState<string>('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewBuildName(event.target.value);
    };

    const handleButtonClick = () => {
        var newBuild: BuildDataObject = {
            identifier: newBuildName
        };

        setNewBuildName("");
        addBuild(newBuild);
    };

    const handleChange = (event: SelectChangeEvent) => {
        var selectedBuildId = event.target.value as string;

        setSelectedBuildId(selectedBuildId);
        setCurrentBuild(selectedBuildId)
    };

    useEffect(() => {
        if (build) {
            setSelectedBuildId(build.id!)
        }
    }, [build]);

    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
                Your Build
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, mt: 5, pb: 2 }}>
                <TextField
                    label="Enter New Build Name"
                    variant="outlined"
                    value={newBuildName}
                    onChange={handleInputChange}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleButtonClick}
                    disabled={newBuildName.trim() === ''}
                >
                    Create new Build
                </Button>
            </Box>

            {
                !build?
                    <p>Please create a build</p>
                    :
                    <>
                        <FormControl sx={{ width: '40%', paddingBottom: 2 }}>
                            <InputLabel id="build-select-label">Select Build</InputLabel>
                            <Select
                                labelId="build-select-label"
                                value={selectedBuildId}
                                onChange={handleChange}
                                label="Select Build"
                            >
                                {builds?.map((build) => (
                                    <MenuItem key={build.id} value={build.id!}>
                                        {build.identifier}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Grid container spacing={4}>
                            {componentSuggestions.map((componentSuggestion, index) =>
                                <ComponentSuggestion
                                    componentSuggestion={componentSuggestion}
                                    key={index}
                                    buildComponents={buildComponents!} removeComponent={removeComponent} />)}
                        </Grid>

                        <Box sx={{ mt: 4, textAlign: 'center' }}>
                            <Button variant="contained" color="primary" startIcon={<SaveIcon />} sx={{ ml: 2 }} onClick={() => handleMoveBuildToCard(build!.id!)}>
                                Add Components to Cart
                            </Button>
                        </Box>
                        <Box sx={{ mt: 2, textAlign: 'center' }}>
                            {/* {Object.values(selectedComponents).some(value => value) ? ( */}
                            {true ? (
                                <Typography variant="body1" color="success.main">
                                    <CheckCircleIcon /> All components are compatible!
                                </Typography>
                            ) : (
                                <Typography variant="body1" color="error.main">
                                    <ErrorIcon /> Incompatible components detected!
                                </Typography>
                            )}
                        </Box>
                    </>
            }

        </Container>
    );
};

export default BuildPage;
