import React from 'react';
import { Box, Card, CardContent, Button, Typography, ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import BuildIcon from '@mui/icons-material/Build';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from "react-router-dom";
import { ComponentDataObject } from '../openapi';
import { useAddComponentToCurrentBuildMutation } from '../app/BuildsApi';
import { useAddComponentToCardMutation } from '../app/OffersApi';

const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: '15px',
    overflow: 'hidden',
    boxShadow: theme.shadows[3],
    position: 'relative',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.02)',
    },
    width: '100%',
    height: '100%',
    '&:hover .favorite-button': {
        opacity: 1,
    }
}));

const Img = styled('img')({
    width: '100%',
    height: '150px',
    objectFit: 'cover',
    display: 'block',
    margin: 'auto',
});

const FavoriteButton = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '40px',
    height: '40px',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    position: 'absolute',
    top: 8,
    right: 8,
    overflow: 'hidden',
    transition: ' 0.3s ease-out', // Smoother transition for collapsing
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    opacity: 0,
    '&:hover': {
        width: '90px',
        borderRadius: '20px',
        transition: '.3s',
    },
    '&:hover .sign': {
        width: '30%',
        paddingLeft: '16px',
        transition: '.3s',
    },
    '&:hover .text': {
        opacity: 1,
        width: '70%',
        paddingRight: '0px',
        paddingTop: '2px'
    },
    '&:active': {
        transform: 'translate(2px, 2px)',
    },
}));

const Sign = styled('div')({
    width: '100%',
    transitionDuration: '0.3s', // Consistent transition duration
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const Text = styled('div')({
    position: 'absolute',
    right: '0%',
    width: '0%',
    opacity: 0,
    color: '#1976d2',
    fontSize: '1em',
    fontWeight: 600,
    transition: 'width 0.3s ease-in, opacity 0.3s ease-in, opacity 0.1s ease-out, width 0.3s ease-out', // Smooth transition for opacity and width
    textAlign: 'center',
    overflow: 'hidden',
});

const ComponentCard: React.FC<{ componentData: ComponentDataObject }> = ({ componentData }) => {
    const navigate = useNavigate();
    const [addComponentToBuild] = useAddComponentToCurrentBuildMutation();
    const [addComponentToOffer] = useAddComponentToCardMutation();

    const imageUrl = componentData.image || "/card_preview_placeholder.png";

    const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = "/card_preview_placeholder.png";
    };

    const handleClick = () => {
        navigate(`/components/${componentData.id}`);
    };

    const handleAddToBuild = () => {
        addComponentToBuild(componentData.id!);
        navigate("/build");
    };

    const handleFavorite = () => {
        console.log("Saved", componentData.id);
        // Implement the save logic here
    };

    return (
        <StyledCard>
            <ButtonBase onClick={handleClick} style={{ display: 'block', width: '100%', textAlign: 'left' }}>
                <Img alt="Component Image" src={imageUrl} onError={handleImageError} />
            </ButtonBase>
            <FavoriteButton className="favorite-button" onClick={handleFavorite}>
                <Sign className="sign">
                    <SaveIcon sx={{ color: '#1976d2' }} />
                </Sign>
                <Text className="text">Save</Text>
            </FavoriteButton>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" component="div"sx={{ fontWeight: 'bold', marginRight: 'auto' }}>
                        {componentData.identifier}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main', marginLeft: 'auto' }}>
                        ${componentData.price.amount}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                    {componentData.type.name}
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        onClick={() => addComponentToOffer(componentData.id!)}
                        variant="contained"
                        size="small"
                        startIcon={<AddShoppingCartIcon />}
                    >
                        Buy
                    </Button>
                    <Button
                        onClick={handleAddToBuild}
                        variant="outlined"
                        size="small"
                        startIcon={<BuildIcon />}
                    >
                        Build
                    </Button>
                </Box>
            </CardContent>
        </StyledCard>
    );
};

export default ComponentCard;
